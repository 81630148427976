import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import {   ActivatedRoute, Params } from '@angular/router';
import {  Homecategoryresult } from './../_models'; 
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-sportslist',
  templateUrl: './sportslist.component.html',
  styleUrls: ['./sportslist.component.css']
})
export class SportslistComponent implements OnInit {
 constructor( private http: HttpClient, private activatedRoute: ActivatedRoute,private helperservice: HelperService) { }
  genre_data;
  routeParams;
  queryParams;
  ob_homecategory:Homecategoryresult;
  genre_name = "";
  data_temp;
  hide_button=true;
  limit = 0;
  ngOnInit() {

    
    this.getRouteParams();   
    const md5object = new Md5();
    const password_digest= (md5object.appendStr(environment.apiToken+this.routeParams.id).end()); 

    const create_api_url = environment.url+"/getsports_genrevideo?gid="+this.routeParams.id+"&digest="+password_digest;
    const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;

    this.http.get(create_api_url + extra_api_url)
    .subscribe(
        val => {    
          this.genre_data = JSON.parse(JSON.stringify(val)); 
          this.genre_data = this.genre_data.result; 
        }
    ); 

   // get home page category  
    let paramhomecategory = { 
        "pid": 1,
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid
    };
    const md5object1 = new Md5();
    const password_digest1= (md5object1.appendStr(environment.apiToken).end()); 
    const home_categories = environment.url+"/gethomepage_category?digest="+password_digest1;
    this.http.post(home_categories,JSON.stringify(paramhomecategory))
    .subscribe(
        val => {   
           this.ob_homecategory = JSON.parse(JSON.stringify(val));
          for (let i = 0; i < this.ob_homecategory.result.length; i++) {
            if(this.ob_homecategory.result[i].genreid==this.routeParams.id)
              this.genre_name = this.ob_homecategory.result[i].name; 
          }
        }
    )

  }

  loadMore(e) {

    this.getRouteParams();   
    const md5object = new Md5();
    const password_digest= (md5object.appendStr(environment.apiToken+this.routeParams.id).end()); 
    this.limit = this.limit + 1;
    const create_api_url = environment.url+"/getmovies_genrevideo?gid="+this.routeParams.id+"&digest="+password_digest+"&li="+this.limit;
    const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
    this.http.get(create_api_url + extra_api_url)
    .subscribe(
        val => {    
          this.data_temp = JSON.parse(JSON.stringify(val));  
          this.genre_data = this.genre_data.concat(this.data_temp.result);
          if(this.data_temp.result.length <= 0)
           this.hide_button = false;
        }
    );  

  }

  
   // Store parameter values on URL changes
   getRouteParams() {

    // Route parameters
    this.activatedRoute.params.subscribe( params => {
        this.routeParams = params;
    });

    // URL query parameters
    this.activatedRoute.queryParams.subscribe( params => {
        this.queryParams = params;
    });
}

}
