import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';

import { User } from './../_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    register(user: User) {
        const md5 = new Md5();
        const digest = (md5.appendStr(environment.apiToken + user.email).end());
        
        const md5_password = new Md5();
        const md5password = (md5_password.appendStr(user.password).end());

        let param = {
            "email":  user.email,
            "password":md5password,
            "firstName":user.firstName,
            "lastName":user.lastName,
            "mobileNo":user.phone,
        }; 

        return this.http.post(`${environment.url}/createUser?digest=${digest}`,JSON.stringify(param)); 
    }

    registerDirect(user: User) {
        const md5 = new Md5();
        const digest = (md5.appendStr(environment.apiToken + user.email).end());
        
        const md5_password = new Md5();
        const md5password = (md5_password.appendStr(user.password).end());

        let param = {
            "email":  user.email,
            "password":user.password,//md5password,
            "firstName":user.firstName,
            "lastName":user.lastName,
            "mobileNo":user.phone,
            "gender":user.gender,
            "age":user.age
        }; 
        // console.log(param);
        return this.http.post(`${environment.url}/register?digest=${digest}`,JSON.stringify(param)); 
    }

    signup(user: User) {
        const md5 = new Md5();
        const digest = (md5.appendStr(environment.apiToken + user.email).end());
        
        const md5_password = new Md5();
        const md5password = (md5_password.appendStr(user.password).end());

        let param = {
            "email":  user.email,
            "password":md5password,
            "firstName":user.firstName,
            "lastName":user.lastName,
            "mobileNo":user.phone,
        }; 

        return this.http.post(`${environment.url}/createUser?digest=${digest}`,JSON.stringify(param)); 
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/users/${id}`);
    }
}
