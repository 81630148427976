import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { Loginsession } from './../_models';
import { User } from './../_models';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    loginresponse: Loginsession;
    constructor(private http: HttpClient, private router: Router) {
        if(localStorage.getItem('currentUser')) {
           this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
           this.currentUser = this.currentUserSubject.asObservable();
        }
    }

    public get currentUserValue() {
        if(localStorage.getItem('currentUser')) {
            return this.currentUserSubject.value;
        } else {      
            return 0;
        } 
    }  

    logout() {
        
        this.loginresponse = JSON.parse(localStorage.getItem('currentUser')); 
        const md5 = new Md5();
        const digest = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end()); 

        const logout_url = environment.url+"/logout?digest="+digest; 
        this.http.post(logout_url,JSON.stringify({extsessionid:this.loginresponse.result.session})).subscribe( 
            val => {    
                localStorage.removeItem('currentUser');
                this.currentUserSubject.next(null); 
               // this.router.navigate(['/login']);
                window.location.reload();
            },
            error => {
                console.warn("This line is never called ",error);
            },
            () => console.warn("registration process completed...")
        );  
        
    }
}