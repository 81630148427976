import { Component, OnInit } from '@angular/core';
import { AlertService, AuthenticationService } from './../_services'; 
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isLoggedIn = false;
  searchD = new FormGroup({
    search : new FormControl(''),
  })
  apiData:any;
  show_sports_menu= false;
  
  constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private router: Router ,
    public http:HttpClient,
    private helperservice: HelperService
  ) {
    if (this.authenticationService.currentUserValue) {
      this.isLoggedIn = true;
    }
  }

  ngOnInit() {
    console.warn(this.authenticationService.currentUserValue);
    const create_api_url = environment.url+"/getSportsMenu";
    const extra_api_url = "?qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
    this.http.get(create_api_url + extra_api_url)
    .subscribe(
        val => { 
          this.apiData = JSON.parse(JSON.stringify(val));   
          this.show_sports_menu = this.apiData.result.is_sport_menu_show == '1' ? true:false;
        }
    ); 

  }

  logout() { 
    this.authenticationService.logout(); 
    this.router.navigate(['/login']);
  }
  searchData()
  {
    console.log("ercjfsfdhskdjfsdj");
    let q= this.searchD.value.search;
    // this.router.navigate(['/search?s=' + encodeURIComponent(q)]);
    this.router.navigate(['/search/' + encodeURIComponent(q)]);
    window.location.href = '/search/' + encodeURIComponent(q);
   
  }

}
