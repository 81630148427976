import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders, HttpErrorResponse   } from '@angular/common/http';
import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { AlertService, AuthenticationService } from '../../_services';
import { Loginsession } from '../../_models';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators'; 
import { HelperService } from '../../_services/helper.service';


@Component({
  selector: 'app-termsconditions',
  templateUrl: './termsconditions.component.html',
  styleUrls: ['./termsconditions.component.css']
})
export class TermsconditionsComponent implements OnInit {

  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private helperservice: HelperService
  ) {


  }
  pagedetail;
  planlist; 
  planclass = "";
  errmessage = ""; 
  ngOnInit() {

      const md5 = new Md5();
      const digest = (md5.appendStr(environment.apiToken).end()); 

      const create_user_api_url = environment.url+"/getterm_condi?digest="+digest;  
      const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
      this.http.get(create_user_api_url + extra_api_url).subscribe(
        val => {
            this.pagedetail = JSON.parse(JSON.stringify(val)); 
            if(parseInt(this.pagedetail.errorCode)==0){     
              this.pagedetail = this.pagedetail.result; 
            }  
        },
        error => {
            console.warn("This line is never called ",error);
        },
        () => console.warn("registration process completed...")
    ); 


  }

}
