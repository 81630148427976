import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from './../../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Loginsession } from './../../_models';
import { Userdetail } from './../../_models';
import { HelperService } from '../../_services/helper.service';

@Component({
  selector: 'app-addedituser',
  templateUrl: './addedituser.component.html',
  styleUrls: ['./addedituser.component.css']
})
export class AddedituserComponent implements OnInit {

  onFocusEvent(event: any) {
    console.log(event);
    if (event.target.type == "text") {
      event.target.type = "date";
    };
  }

  userForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  resmessage = '';
  successmessage = '';
  loginresponse: Loginsession;
  login_user_session = '';
  userdetail: Userdetail;
  token;
  is_corporate_acc;
  role_type;
  successmsg;
  showvariable=true;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private helperservice: HelperService
    ) {

    this.loginresponse = JSON.parse(localStorage.getItem('currentUser'));
    const md5 = new Md5();
    this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end());
    this.login_user_session = this.loginresponse.result.session;
    this.successmessage = localStorage.getItem('err_msg');
    localStorage.setItem('err_msg', '');
    this.userid = this.loginresponse.result.userid;
    this.is_corporate_acc = this.loginresponse.result.is_corporate_acc;
    this.role_type = this.loginresponse.result.role_type;

  }

  ngOnInit() {

    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      mobileNo: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['M'],
      status: [''],
      update_user_id: [''],
    });
  }

  get f() { return this.userForm.controls; }

  userid;
  emailerror = '';
  firstName = "";
  lastName = "";
  emailfld = "";
  dob = "";
  status_data = 0;
  gender = "";
  mobileNo = "";
  status = 1;
  resp;
  updatedId = 0;
  dispTitle = "Add User";

  isEmail(search: string): boolean {
    let serchfind: boolean;
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    serchfind = regexp.test(search);
    return serchfind
  }

  listOptions = [
    { id: 0, name: "" },
    { id: 1, name: "Active" },
    { id: 2, name: "Inactive" },
    { id: 3, name: "Block" },
  ];
  selectStatusChange($event) {
    this.status_data = this.listOptions[$event].id;
  }

  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.userForm.invalid) {
      return;
    }

    //this.loading = true;

    // if(this.userForm.value.email != '') {
    //     if (!this.isEmail(this.userForm.value.email)) { 
    //       this.emailerror = 'Email must be a valid email address';
    //       return;
    //     } else {
    //       this.emailerror = '';
    //     }
    // } 

    if (this.updatedId > 0) {
      let param = {
        update_user_id: this.updatedId,
        user_id: this.userid,
        firstName: this.f.firstName.value,
        lastName: this.f.lastName.value,
        mobileNo: this.f.mobileNo.value,
        email: this.f.email.value,
        dob: this.f.dob.value,
        gender: this.f.gender.value,
        status: this.f.status.value,
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid
      };

      //console.log(param);

      const md5object3 = new Md5();
      const digest3 = (md5object3.appendStr(environment.apiToken + this.userid).end());

      const create_user_api_url = environment.url + "/update_corpo_user?digest=" + digest3;
      this.http.post(create_user_api_url, JSON.stringify(param))
        .subscribe(
          val => {
            this.resp = JSON.parse(JSON.stringify(val));
            console.log(this.resp);
            if (this.resp.result) {
              this.successmessage = "User updated successfully";
            }
            else
              this.resmessage = this.resp.errorMessage;
            //this.updatedId=this.resp.result.userId;
            //window.location=this.currentUrl;

          }
        )
    }
    else {
      let param = {
        user_id: this.userid,
        firstName: this.f.firstName.value,
        lastName: this.f.lastName.value,
        mobileNo: this.f.mobileNo.value,
        email: this.f.email.value,
        dob: this.f.dob.value,
        gender: this.f.gender.value,
        status: this.f.status.value,
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid
      };

      //console.log(param);

      const md5object3 = new Md5();
      const digest3 = (md5object3.appendStr(environment.apiToken + this.userid).end());

      const create_user_api_url = environment.url + "/add_corpo_user?digest=" + digest3;
      this.http.post(create_user_api_url, JSON.stringify(param))
        .subscribe(
          val => {
            this.resp = JSON.parse(JSON.stringify(val));
            console.log(this.resp);
            
            if (this.resp.result.userId) {
              this.updatedId = this.resp.result.userId;
              this.resmessage = "";
              this.successmessage = "User added successfully";
              this.showvariable = false;
              this.dispTitle = "Edit User";
            }
            else {
              this.successmessage = "";
              this.resmessage = this.resp.errorMessage;
            }


            //window.location=this.currentUrl;

          }
        )
    }


  }

  adduser() {
    let param = {
      user_id: this.userid,
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      mobileNo: this.f.mobileNo.value,
      email: this.f.email.value,
      dob: this.f.dob.value,
      gender: this.f.gender.value,
      status: this.f.status.value,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    };
    const md5object3 = new Md5();
    const digest3 = (md5object3.appendStr(environment.apiToken + this.userid).end());

    const create_user_api_url = environment.url + "/add_corpo_user?digest=" + digest3;
    this.http.post(create_user_api_url, JSON.stringify(param))
      .subscribe(
        val => {
          this.resp = JSON.parse(JSON.stringify(val));
          console.log(this.resp);
          if (this.resp.result.userId) {
            // this.updatedId = this.resp.result.userId;
            this.resmessage = "";
            this.successmessage = "User added successfully";
            localStorage.setItem('err_msg', "User added successfully");
            window.location.reload();
          }
          else {
            this.successmessage = "";
            this.resmessage = this.resp.errorMessage;
          }
        }
      )
  }

}
