import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Loginsession } from './../../_models';
import { Userdetail } from './../../_models';
import { HelperService } from '../../_services/helper.service';


@Component({
  selector: 'app-subscriptiondetails',
  templateUrl: './subscriptiondetails.component.html',
  styleUrls: ['./subscriptiondetails.component.css']
})
export class SubscriptiondetailsComponent implements OnInit {

  userdetail: Userdetail;
  loginresponse: Loginsession;
  login_user_session = '';
  token;
  is_corporate_acc;
  role_type;
  resmessage;
  status;
  uid;
  respD;
  currentUrl;
  sub_type;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private helperservice: HelperService) { 
      this.loginresponse = JSON.parse(localStorage.getItem('currentUser'));  
    const md5 = new Md5(); 
    this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end()); 
    this.login_user_session = this.loginresponse.result.session;
    this.is_corporate_acc = this.loginresponse.result.is_corporate_acc;
    this.role_type = this.loginresponse.result.role_type;
    }

  ngOnInit() {
    this.getUserData();
    this.currentUrl = this.router.url;
    this.currentUrl = this.currentUrl.replace("#open-modal", "");
    this.currentUrl = this.currentUrl.replace("#close", "");
  }

  getUserData(){ 

    let uparam = { 
      "extsessionid":this.login_user_session,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    };  

    const create_user_api_url = environment.url+"/getUserData?digest="+this.token;  
    this.http.post(create_user_api_url,JSON.stringify(uparam))
      .subscribe(
          val => {  
              this.userdetail =  JSON.parse(JSON.stringify(val));
              console.log(this.userdetail); 
              const mapped = Object.keys(val).map(key =>  ((key)=='username')?val[key]:"" );
              this.resmessage = environment.error.getUserData[mapped[0].value];
              this.status = this.userdetail.result.is_unsubscribe;
              console.log(this.status);
              this.uid = this.userdetail.result.user_id;
              console.log(this.uid);
              this.sub_type=this.userdetail.result.subscription_type;

             

          },
          error => {
              console.warn("This line is never called ",error);
          }
      ); 
  }
  unsub1()
  {
    let ele:HTMLElement=document.getElementById('popuplnk') as HTMLElement;
    ele.click();
  }

  resub1()
  {
    let ele:HTMLElement=document.getElementById('resubpopuplnk') as HTMLElement;
    ele.click();
  }

  unsub(){
    // alert("hi");
    let param = {
      "user_id":this.uid,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    }
        const md5object1 = new Md5();
        const digest1= (md5object1.appendStr(environment.apiToken+this.uid).end());
        const create_user_api_url = environment.url+"/unsubscribe_user?digest="+digest1;
        this.http.post(create_user_api_url, JSON.stringify(param))
            .subscribe(
              val => {
                this.respD = JSON.parse(JSON.stringify(val));
                console.log(this.respD);
                window.location.href='/account/subscriptiondetails';
              }
            )
  }

  resub(){
    let param = {
      "user_id":this.uid,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    }
        const md5object1 = new Md5();
        const digest1= (md5object1.appendStr(environment.apiToken+this.uid).end());
        const create_user_api_url = environment.url+"/resubscribe_user?digest="+digest1;
        this.http.post(create_user_api_url, JSON.stringify(param))
            .subscribe(
              val => {
                this.respD = JSON.parse(JSON.stringify(val));
                console.log(this.respD);
                window.location.href='/account/subscriptiondetails';
              }
            )
  }
}
