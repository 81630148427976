import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EmbedVideoService } from 'ngx-embed-video';
import { AlertService, AuthenticationService } from './../_services';
import { Loginsession,Homecategory,Homecategoryitemroot } from './../_models';
import { Md5 } from 'ts-md5/dist/md5';
import { environment } from './../../environments/environment'; 
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-middle',
  templateUrl: './middle.component.html',
  styleUrls: ['./middle.component.css']
})
export class MiddleComponent implements OnInit {

  youtubeUrl = 'https://www.youtube.com/watch?v=iHhcHTlGtRs';
  youtubeUrl2 = 'https://www.youtube.com/watch?v=DwxzK8ipVkg';
  youtubeUrl3 = 'https://www.youtube.com/watch?v=pWi-Qtw5RBU';
  youtubeUrl4 = 'https://www.youtube.com/watch?v=te3K0-Q_sPU';
  youtubeUrl5 = 'https://www.youtube.com/watch?v=cjsJPPZF4TA';

  youtubeUrl6 = 'https://www.youtube.com/watch?v=oWuY_0gzNMU';
  youtubeUrl7 = 'https://www.youtube.com/watch?v=8kooIgKESYE';
  youtubeUrl8 = 'https://www.youtube.com/watch?v=CwzjlmBLfrQ';
  youtubeUrl9 = 'https://www.youtube.com/watch?v=FuiJHJz4f5Q';
  youtubeUrl10 = 'https://www.youtube.com/watch?v=ndl1W4ltcmg';
 
  iframe_html: any;
  iframe_html2: any;
  iframe_html3: any;
  iframe_html4: any;
  iframe_html5: any;

  iframe_html6: any;
  iframe_html7: any;
  iframe_html8: any;
  iframe_html9: any;
  iframe_html10: any;
  isLoggedIn = false;

  loginresponse: Loginsession;

  routeParams: Params;
  queryParams: Params;

  homecategory: Homecategory;
  homecategoryitem:Homecategoryitemroot;

  constructor(private embedService: EmbedVideoService,   
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private http: HttpClient
    ) { 
    const videoOptions = {
      query: { portrait: 0, color: '333' },
      attr: { width: '100%', height: 500 }
    };
    this.iframe_html = this.embedService.embed(this.youtubeUrl, videoOptions);
    this.iframe_html2 = this.embedService.embed(this.youtubeUrl2, videoOptions);
    this.iframe_html3 = this.embedService.embed(this.youtubeUrl3, videoOptions);
    this.iframe_html4 = this.embedService.embed(this.youtubeUrl4, videoOptions);
    this.iframe_html5 = this.embedService.embed(this.youtubeUrl5, videoOptions);

    this.iframe_html6 = this.embedService.embed(this.youtubeUrl6, videoOptions);
    this.iframe_html7 = this.embedService.embed(this.youtubeUrl7, videoOptions);
    this.iframe_html8 = this.embedService.embed(this.youtubeUrl8, videoOptions);
    this.iframe_html9 = this.embedService.embed(this.youtubeUrl9, videoOptions);
    this.iframe_html10 = this.embedService.embed(this.youtubeUrl10, videoOptions);

    if (this.authenticationService.currentUserValue) {
      this.isLoggedIn = true;
    }
  }


  slides = [];
  //login_user_session = '';
  slickInit(){
    
  }
  ngOnInit() { 
/*
      // API slider category 
      const md5object = new Md5();
      const digest2 = (md5object.appendStr(environment.apiToken + "845").end());
      const api_url2 = environment.apiMobUrl+"/getCategories?version=2&clientId=33&portalid=288&digest="+digest2+"&channelid=845";
      this.http.get(api_url2)
      .subscribe(
          val => {     
              this.homecategory = JSON.parse(JSON.stringify(val));
              let homecat:Homecategory;   
              for (let i = 0; i < this.homecategory.length; i++) {
                //console.warn(this.homecategory[i].id);
                 
                 // API slider category 
                 this.homecategory[i].name = this.homecategory[i].name.replace("_", ' ').replace("_", ' ').replace("_", ' ').replace("_", ' ').replace("_", ' ').replace("_", ' ');
                 let category_id = this.homecategory[i].id; 
                 let thingsArray = [];
                 let md5object = new Md5();
                 let digest23 = (md5object.appendStr(environment.apiToken + category_id).end());  
                 let appurl = environment.apiMobUrl+"/getCategoryItems?categoryid="+category_id+"&digest="+digest23+"&version=2&clientId=33";
                 this.http.get(appurl)
                 .subscribe(
                     val => {     
                         this.homecategoryitem = JSON.parse(JSON.stringify(val));   
                         this.homecategory[i].homecategoryitem = this.homecategoryitem; 
                         for(let ind=0; ind<this.homecategory[i].homecategoryitem.length; ind++){
                           if(this.homecategory[i].homecategoryitem[ind].thumbnail.indexOf("/") <= 0){
                            this.homecategory[i].homecategoryitem[ind].thumbnail =  "assets/images/edif_videoicon.png";
                              if(this.homecategory[i].homecategoryitem[ind].series)
                                this.homecategory[i].homecategoryitem[ind].url = "/series-detail/"+this.homecategory[i].homecategoryitem[ind].id; 
                              else
                                this.homecategory[i].homecategoryitem[ind].url = "/play-movie/"+this.homecategory[i].homecategoryitem[ind].id; 

                           }  
                          
                         }
                        // console.warn(this.homecategory);
                     }, 
                     error => {
                         console.warn("This line is never called ",error);
                     } 
                 );   
                 // API slider category end 
              } 
               
          },
          error => {
              console.warn("This line is never called ",error);
          } 
      );    
      // API slider category end 

      this.loginresponse = JSON.parse(localStorage.getItem('currentUser')); 
      // const md5 = new Md5();
      //const digest = (md5.appendStr(environment.apiToken + this.loginresponse.login[0].session).end()); 
      // this.login_user_session = this.loginresponse.login[0].session;

      let video_url_1 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/maleficent1-HD.mp4/playlist.m3u8?extsessionid='+this.loginresponse.login[0].session;
      let video_url_2 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/abominable-HD.mp4/playlist.m3u8?extsessionid='+this.loginresponse.login[0].session;
      let video_url_3 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/joker-HD.mp4/playlist.m3u8?extsessionid='+this.loginresponse.login[0].session;
      let video_url_4 = "http://packageb.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageB/Programs/starwars_vod-25f-16x9-HD.mp4/playlist.m3u8?extsessionid="+this.loginresponse.login[0].session;
      let video_url_5 = 'http://packageb.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageB/Programs/maleficient2_vod-25f-16x9-HD.mp4/playlist.m3u8?extsessionid='+this.loginresponse.login[0].session;
  
      this.slides = [
        {id:"video_1", img: "assets/images/popular-pic1.jpg", video:video_url_1, caption:"Ballad of Buster Scruggs 1 ", title:"Drama"},
        {id:"video_2", img: "assets/images/popular-pic2.jpg", video:video_url_2, caption:"Ballad of Buster Scruggs 2", title:"Drama"},
        {id:"video_3", img: "assets/images/popular-pic3.jpg", video:video_url_3, caption:"Ballad of Buster Scruggs 3", title:"Drama"},
        {id:"video_4", img: "assets/images/popular-pic4.jpg", video:video_url_4, caption:"Ballad of Buster Scruggs 4", title:"Drama"},
        {id:"video_5", img: "assets/images/popular-pic1.jpg", video:video_url_5, caption:"Ballad of Buster Scruggs 5", title:"Drama"},
      ];
*/
  } 
  /*
  slideConfig = {
    "slidesToShow": 4, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":true
  };
// Thrital Sec
  slides2 = [
    {img: "assets/images/thrilar_slideimg1.jpg", caption:"Ballad of Buster Scruggs", title:"Drama"},
    {img: "assets/images/thrilar_slideimg2.jpg", caption:"Ballad of Buster Scruggs", title:"Drama"},
    {img: "assets/images/thrilar_slideimg3.jpg", caption:"Ballad of Buster Scruggs", title:"Drama"},
    {img: "assets/images/thrilar_slideimg4.jpg", caption:"Ballad of Buster Scruggs", title:"Drama"},
    {img: "assets/images/thrilar_slideimg2.jpg", caption:"Ballad of Buster Scruggs", title:"Drama"},
  ];
  
  slideConfig2 = {
    "slidesToShow": 4, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":true
  };

  // Editor slider 
  slides_editor = [
    {img: "assets/images/editors_img1.jpg", caption:"Education"},
    {img: "assets/images/editors_img2.jpg", caption:"Business"},
    {img: "assets/images/editors_img3.jpg", caption:"Entertainment"},
    {img: "assets/images/editors_img4.jpg", caption:"Lifestyle"},
    {img: "assets/images/editors_img5.jpg", caption:"Photography"},
    {img: "assets/images/editors_img6.jpg", caption:"Sports"},
    {img: "assets/images/editors_img3.jpg", caption:"Entertainment"},
  ];
  
  slideConfig_editor = {
    "slidesToShow": 6, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  };
  */
}
