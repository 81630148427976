import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { HttpClient,HttpParams,HttpHeaders, HttpErrorResponse   } from '@angular/common/http';
import { AlertService, AuthenticationService } from '../../_services';
import { Loginsession } from '../../_models';
import { Userdetail,Userloginreq } from '../../_models';
import { from } from 'rxjs';
import { HelperService } from '../../_services/helper.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginresponse: Loginsession;
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    success;
    messagetype;
    loginmessage: string;
    showButton = false;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private helperservice: HelperService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.success = this.route.snapshot.queryParams['success'] || '0';
        this.messagetype = this.route.snapshot.queryParams['type'] || '';

        if(this.success==1 && this.messagetype=='resubscribe')
        {
            this.loginmessage="you have successfully Resubscribed the subscription."
        }
        
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        const login_error = [];

        /* login_error["113"]="Maximum number of sessions per user exceeded";
        login_error["118"]="Client doesn’t have access to portal";
        login_error["119"]="Client doesn’t have access to API Call";
        login_error["120"]="Digest error";
        login_error["121"]="Client id is not valid";
        login_error["122"]="Version does not exist";
        login_error["137"]="User not activated";
        login_error["203"]="Username or Password Wrong";
        login_error["204"]="Portal id is missing";
        login_error["218"]="Digest is missing";
        login_error["219"]="Client id is missing";
        login_error["220"]="Version is missing";
        login_error["221"]="Username is missing";
        login_error["222"]="Password is missing"; */
 
        const md5 = new Md5();
        const digest = (md5.appendStr(environment.apiToken + this.f.username.value).end());
        const md51 = new Md5();
        const md5pass = (md51.appendStr(this.f.password.value).end());
        const hash = (digest + this.f.password.value);
 
        const create_user_api_url = environment.url+"/login?digest="+digest;  
       
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        let param:Userloginreq = {
            "email":  this.f.username.value,
            "password": md5pass.toString()
        };
        let param1 = {
            device: this.helperservice.dev,
            device_unique: this.helperservice.browserversion,
            qid: this.helperservice.qid
          };

        let merge = {...param,...param1}
  
        this.http.post(create_user_api_url,JSON.stringify(merge)).subscribe(
            val => {    
                this.loginresponse = JSON.parse(JSON.stringify(val));   
                console.log(this.loginresponse);
                if( parseInt(this.loginresponse.errorCode)<=0){  
                    this.alertService.success('Login successful', true);
                    localStorage.setItem('currentUser', JSON.stringify(this.loginresponse)); 
                    this.router.navigate([this.returnUrl]);
                    window.location.reload(); 
                } else {
                    if(parseInt(this.loginresponse.errorCode)==199)
                    {
                        this.showButton =true;
                        localStorage.setItem('userid', JSON.stringify(this.loginresponse.result.userid));
                    }
                    this.loginmessage = environment.error.login[parseInt(this.loginresponse.errorCode)]; 
                    this.loading = false;
                }  
            },
            error => {
                console.warn("This line is never called ",error);
            },
            () => console.warn("registration process completed...")
        ); 
    }

}
