import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Loginsession } from './../../_models';
import { Userdetail } from './../../_models';
import { HelperService } from '../../_services/helper.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  accountForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  resmessage= '';
  successmessage= '';
  loginresponse: Loginsession;
  login_user_session = '';
  userdetail: Userdetail;
  token;
  is_corporate_acc;
  role_type;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private helperservice: HelperService) {
      
    this.loginresponse = JSON.parse(localStorage.getItem('currentUser')); 
    const md5 = new Md5();
    this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end()); 
    this.login_user_session = this.loginresponse.result.session;
    this.is_corporate_acc = this.loginresponse.result.is_corporate_acc;
    this.role_type = this.loginresponse.result.role_type;  
  }

  
  ngOnInit() {
      this.accountForm = this.formBuilder.group({
          oldpassword: ['', Validators.required],
          password: ['', Validators.required],
          cnfpassword: ['', Validators.required] 
      }); 
  }

  
  // convenience getter for easy access to form fields
  get f() { return this.accountForm.controls; }
  onSubmit() {

      this.submitted = true;
 
      // stop here if form is invalid
      if (this.accountForm.invalid) {
          return;
      } 

      this.loading = true; 
      const login_error = [];   
      /* login_error[118]="Client doesn't have access to portal"; 
      login_error[119]="Client doesn't have access to API Call"; 
      login_error[120]="Digest error"; 
      login_error[121]="Client id is not valid"; 
      login_error[122]="Version does not exist"; 
      login_error[127]="User id error"; 
      login_error[204]="Portal id is missing"; 
      login_error[218]="Digest is missing"; 
      login_error[219]="Client id is missing"; 
      login_error[220]="Version is missing"; 
      login_error[236]="User id is missing";  
      login_error[123]="Username Error"; 
      login_error[124]="Password is not valid"; 
      login_error[126]="New password is not valid"; 
      login_error[131]="Password has not changed";  
      login_error[203]="Username or Password Wrong"; 
      login_error[221]="Username is missing";  
      login_error[222]="Password is missing";  
      login_error[235]="new password is missing";   */

        let uparam = { 
            "extsessionid":this.login_user_session,
            device: this.helperservice.dev,
                device_unique: this.helperservice.browserversion,
                qid: this.helperservice.qid
        }; 

        if( (this.f.password.value).length < 6 ) {
            this.resmessage = "Password must be more than six characters.";
            this.successmessage = "";
            this.loading = false; 
            return;
        }

       const user_api_url = environment.url+"/getUserData?digest="+this.token;  
       this.http.post(user_api_url,JSON.stringify(uparam))
         .subscribe(
            val => {  
               this.userdetail =  JSON.parse(JSON.stringify(val));   

               const mapped = Object.keys(val).map(key =>  ((key)=='username')?val[key]:"" );
               const filtered = mapped.filter(function (el) {
                    return el != "";
               }); 
               this.resmessage = environment.error.getUserData[mapped[0].value];
               const md5object = new Md5();
               
               const oldpass_md = new Md5();
               const oldpass_md5 = (oldpass_md.appendStr(this.f.oldpassword.value).end()); 
               const newpass_md = new Md5();
               const newpass_md5 = (newpass_md.appendStr(this.f.password.value).end()); 
               const password_digest= (md5object.appendStr(environment.apiToken +newpass_md5).end()); 

               let param = { 
                    "username":this.userdetail.result.username,
                    "password":oldpass_md5,
                    "newpass":newpass_md5,
                    device: this.helperservice.dev,
                    device_unique: this.helperservice.browserversion,
                    qid: this.helperservice.qid
               };
               
               const create_user_api_url = environment.url+"/changePassword?digest="+password_digest;
               this.http.post(create_user_api_url,JSON.stringify(param)).subscribe(
                   val => {   
                       this.userdetail = JSON.parse(JSON.stringify(val));   
                       const mapped = Object.keys(val).map(key => ({type: key, value: val[key]})); 
 
                       if(parseInt(mapped[0].value)==0){        
                           this.successmessage = "Your password changed successfully.";
                           this.resmessage = "";
                           this.loading = false; 
                       } else {
                           this.resmessage = environment.error.changePassword[mapped[0].value];
                           this.successmessage = ""; 
                           this.loading = false; 
                       }  
                   },
                   error => {
                       console.warn("This line is never called ",error);
                   },
                   () => console.warn("registration process completed...")
               ); 




              },
            error => {
                console.warn("This line is never called ",error);
            }
        ); 


     

   }

}
