import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from './../../_services';
import { Loginsession } from './../../_models';
import { $ } from 'protractor';
import { HelperService } from '../../_services/helper.service';

@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.css']
})
export class FavouriteComponent implements OnInit {
  private login_user_session: string;
  isLoggedIn = false;
  loginresponse: Loginsession;
  token;
  favourite : any;
  userid;
  apiid;
  favourite_data;
  currentUrl;
  is_corporate_acc;
  role_type;

  constructor(private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private helperservice: HelperService
    ) {
      if (localStorage.getItem('currentUser')) {
        this.loginresponse = JSON.parse(localStorage.getItem('currentUser'));
        // this.loginresponse.result.is_corporate_acc
        const md5 = new Md5();
        this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end());
        this.login_user_session = this.loginresponse.result.session;
        // let le = this.loginresponse.result.userid
        this.isLoggedIn = true;
        this.userid = this.loginresponse.result.userid;
        this.is_corporate_acc = this.loginresponse.result.is_corporate_acc;
        this.role_type = this.loginresponse.result.role_type;
        console.log(this.loginresponse.result);
        // this.extsessionid=  this.login_user_session;
      }

     }

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.currentUrl = this.currentUrl.replace("#open-modal", "");
    this.currentUrl = this.currentUrl.replace("#close", "");

    let param = {
      user_id:  this.userid,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    };

    const md5object3 = new Md5();
    const digest3 = (md5object3.appendStr( environment.apiToken+this.userid ).end());
    const api_url3 = environment.url + "/getuser_favourite?digest=" + digest3;
    this.http.post(api_url3, JSON.stringify(param))
      .subscribe(
        val => {
          this.favourite = JSON.parse(JSON.stringify(val));
          //console.log(this.favourite);
          // if(this.favourite.result.success==1)
          // {
          //   // alert("favourite added");
          //   this.successmsg="Favourite Added."
          //   alert(this.successmsg);
          //   this.favouritemsg=true;
          // }
          // else{
          //   // alert(this.favourite.errorMessage);
          //   this.alreadymsg=this.favourite.errorMessage
          //   this.favouritemsg=false;
          // }
          // this.is_subscription=this.subscription.result.is_subscription;
          // // this.is_subscription=2;
        }
      )

  }
  favdelid='';

  onDelete1()
  {
    if(this.favdelid!='')
    {
      let param = {
        id: this.favdelid,
        user_id:  this.userid,
        device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
      };
      const md5object3 = new Md5();
      const digest3 = (md5object3.appendStr( environment.apiToken+this.userid ).end());
      const api_url3 = environment.url + "/remove_favourite?digest=" + digest3;
      this.http.post(api_url3, JSON.stringify(param))
        .subscribe(
          val => {
            this.favourite = JSON.parse(JSON.stringify(val));
            console.log(this.favourite);
            this.favdelid='';
            window.location=this.currentUrl;
          
          }
        )
    }
  }

  

  onDelete(id)
  {
    this.favdelid=id;
    let ele:HTMLElement=document.getElementById('popuplnk') as HTMLElement;
    ele.click();
  }

}
