import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import { environment } from './../../../environments/environment';
import { Loginsession } from './../../_models';
import { HelperService } from '../../_services/helper.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  token;
  loginresponse:Loginsession;
  msg;
  is_corporate_acc;
  role_type;

  constructor(private http: HttpClient,private helperservice: HelperService) { 
    this.loginresponse = JSON.parse(localStorage.getItem('currentUser')); 
    const md5 = new Md5();
    this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end()); 
    this.is_corporate_acc = this.loginresponse.result.is_corporate_acc;
    this.role_type = this.loginresponse.result.role_type;
  }
  orderhistory;
  ngOnInit() {
 
      const md5object = new Md5();
      const digest2 = (md5object.appendStr(environment.apiToken + this.loginresponse.result.session).end());
      const api_url2 = environment.url+"/getUserOrderHistory?digest="+digest2;

      let param = { 
            "extsessionid":this.loginresponse.result.session,
            device: this.helperservice.dev,
            device_unique: this.helperservice.browserversion,
            qid: this.helperservice.qid
      };
      this.http.post(api_url2,JSON.stringify(param)).subscribe(
          val => {     
               this.orderhistory = JSON.parse(JSON.stringify(val));
               console.warn(this.orderhistory);
               if( this.orderhistory.result.length <= 0 ) {
                  this.msg = 'No order found.';
               } 
               if( parseInt(this.orderhistory.errorCode) != 0 ) {
                  this.msg = environment.error.getUserOrderHistory[parseInt(this.orderhistory.errorCode)]; 
               } 
          }
      ) 

  }

}
