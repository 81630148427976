import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { environment } from './../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import {HttpClient} from '@angular/common/http';
import { HelperService } from '../_services/helper.service';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  public showList;
  constructor(public http:HttpClient,private router:Router,private active:ActivatedRoute, private helperservice: HelperService) { }

  ngOnInit() {
    let search=this.active.snapshot.paramMap.get('search');
    console.log(search);
    // this.add.search(data).subscribe((result)=>{
    
    // })
    let param={
      "txt" : search,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    };
    const md5object = new Md5();
    const password_digest= (md5object.appendStr(environment.apiToken+search).end()); 

     const create_api_url = environment.url+"/get_wesearch_result?digest="+password_digest;
    // const create_api_url = environment.url+"/get_wesearch_result" ;
    this.http.post(create_api_url,JSON.stringify(param))
    .subscribe(
        val => { 
            
          this.showList = JSON.parse(JSON.stringify(val));  
          this.showList = this.showList.result;
          
        }
    );  
  }

}
