import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Loginsession } from './../../_models';
import { Userdetail } from './../../_models';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { elementAt } from 'rxjs/operators';
import { element } from 'protractor';
import { HelperService } from '../../_services/helper.service';


@Component({
  selector: 'app-usersmanagement',
  templateUrl: './usersmanagement.component.html',
  styleUrls: ['./usersmanagement.component.css']
})
export class UsersmanagementComponent implements OnInit {
  @ViewChild('avatar', {
    static: true
  }) avatar;
  formData;
  page = 1;
  checkArray = [];
  count = 0;
  currentIndex = 0;
  tableSize = 10;
  tableSizes = [10, 25, 50, 100];
  loginresponse: Loginsession;
  token;
  login_user_session = '';
  userid;
  resp;
  page_number;
  search_txt;
  sort_field;
  i_id;
  active_user = 0;
  remaining_users = 0;
  fn;
  e;
  delete_user_id;
  currentUrl;
  fileToUpload: File = null;
  is_corporate_acc;
  saveFileForm: any;
  successmsg = "";
  role_type;
  activeted_user;
  masterSelected = false;
  showProgress=false;
  progressVal=10;
  logoToUpload;


  // private options: string[] = ["Sort by","Name Asc", "Name Desc", "Email Asc","Email Desc"];
  // selectedQuantity = "Sort by";

  countries = [
    { id: 'ASC,fn', name: " Name A - Z" },
    { id: 'DESC,fn', name: "Name Z - A" },
    { id: 'ASC,e', name: "Email A - Z" },
    { id: 'DESC,e', name: "Email Z - A" }
  ];
  // selectedQuantity = "Sort by";


  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,private helperservice: HelperService) {
    this.loginresponse = JSON.parse(localStorage.getItem('currentUser'));
    this.successmsg = localStorage.getItem('err_msg');
    localStorage.setItem('err_msg', '');
    const md5 = new Md5();
    this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end());
    this.login_user_session = this.loginresponse.result.session;

    this.userid = this.loginresponse.result.userid;
    this.is_corporate_acc = this.loginresponse.result.is_corporate_acc;
    this.role_type = this.loginresponse.result.role_type;
  }

  selectD = new FormGroup({
    select: new FormControl(''),
  })



  onCheckboxChange(e) {
    if (e.target.checked) {
      let data = this.resp.result.user_data;
      for (var i = 0; i < data.length; i++) {
        if (data[i].id == e.target.value) {
          data[i].isSelected = true;
        }

      }

    } else {
      let data = this.resp.result.user_data;
      for (var i = 0; i < data.length; i++) {
        if (data[i].id == e.target.value) {
          data[i].isSelected = false;
        }
      }
    }
  }

  onCheckboxChanges(e) {
    // console.log(start_index);
    // console.log(this.tableSize);
    console.log("PAGE", this.page)

    // let start_index:number =this.page==1?1:((this.page-1)*this.tableSize);
    let start_index: number = (this.page - 1) * 10;
    let end_index: number = (start_index + this.tableSize) - 1;
    console.log("SI", start_index);
    console.log("END", end_index);

    let data = this.resp.result.user_data;
    if (e.target.checked) {
      for (var i = start_index; i <= end_index; i++) {
        if (data[i] != null) {
          data[i].isSelected = true;
        }

      }
    } else {
      for (var i = start_index; i <= end_index; i++) {
        if (data[i] != null) {
          data[i].isSelected = false;
        }

      }
    }
    // if (e.target.checked) {
    //   this.checkArray = [];
    //   if (this.resp.result.user_data.length > 0) {
    //     let data = this.resp.result.user_data;
    //     data.forEach(element => {
    //       this.checkArray.push(element.id.toString());

    //     });
    //     console.log(this.checkArray);
    //   }
    // }
    // else {
    //   this.checkArray = [];
    // }

  }

  active_account() {
    console.log(this.checkArray);
    this.successmsg = "";
    this.checkArray = [];
    let data = this.resp.result.user_data;
    let useridlist = "";

    for (var i = 0; i < data.length; i++) {
      if (data[i].isSelected == true) {
        this.checkArray.push(data[i].id.toString());
      }
    }

    if (this.checkArray.length > 0) {
      let param = {
        user_id_str: this.checkArray.join(","),
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid
      }
      const md5object = new Md5();
      const digest = (md5object.appendStr(environment.apiToken).end());
      const create_user_api_url = environment.url + "/sent_multiple_user_activation_link?digest=" + digest;
      this.http.post(create_user_api_url, JSON.stringify(param))
        .subscribe(
          val => {
            let respnse = JSON.parse(JSON.stringify(val));
            console.log(respnse);
            window.location.href = this.currentUrl;
          }
        )
    }
    else {
      this.successmsg = "Please select user to send activation link."
    }
  }

  selectData() {
    // alert("hello");
    let q = this.selectD.value.select;
    //  console.log(q);
    let nameArr = q.split(',');
    console.log(nameArr);
    nameArr[0];
    nameArr[1];
    let param = {
      user_id: this.userid,
      page_number: 1,
      search_txt: '',
      sort_field: nameArr[1],
      sort_type: nameArr[0],
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    }
    const md5object = new Md5();
    const digest = (md5object.appendStr(environment.apiToken + this.userid).end());
    const create_user_api_url = environment.url + "/get_corpo_user?digest=" + digest;
    this.http.post(create_user_api_url, JSON.stringify(param))
      .subscribe(
        val => {
          this.resp = JSON.parse(JSON.stringify(val));
          // console.log(this.resp);
          this.active_user = this.resp.result.total_user;
          this.remaining_users = this.resp.result.remaining_total_user;
        }
      )
  }

  searchD = new FormGroup({
    search: new FormControl(''),
  })

  searchData() {
    // console.log("ercjfsfdhskdjfsdj");
    let q = this.searchD.value.search;
    console.log(q);
    let param = {
      user_id: this.userid,
      page_number: 1,
      search_txt: this.searchD.value.search,
      sort_field: '',
      sort_type: '',
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    }
    const md5object = new Md5();
    const digest = (md5object.appendStr(environment.apiToken + this.userid).end());
    const create_user_api_url = environment.url + "/get_corpo_user?digest=" + digest;
    this.http.post(create_user_api_url, JSON.stringify(param))
      .subscribe(
        val => {
          this.resp = JSON.parse(JSON.stringify(val));
        }
      )
  }

  showrecord()
  {
    this.tableSize = this.active_user;
    this.page = 1;
    this.masterSelected = false;
  }


  // selectData()
  // {
  //   // console.log("ercjfsfdhskdjfsdj");
  //   let q= this.selectD.value.select;
  //   console.log(q);


  ngOnInit() {

    this.saveFileForm = this.formBuilder.group({
    });
    this.currentUrl = this.router.url;
    this.currentUrl = this.currentUrl.replace("#open-modal", "");
    this.currentUrl = this.currentUrl.replace("#close", "");

    let param = {
      user_id: this.userid,
      page_number: 1,
      search_txt: '',
      sort_field: '',
      sort_type: '',
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    }
    const md5object = new Md5();
    const digest = (md5object.appendStr(environment.apiToken + this.userid).end());
    const create_user_api_url = environment.url + "/get_corpo_user?digest=" + digest;
    this.http.post(create_user_api_url, JSON.stringify(param))
      .subscribe(
        val => {
          let temp = JSON.parse(JSON.stringify(val));
          let temp_user = temp.result.user_data.map(v => Object.assign(v, { 'isSelected': false }))
          temp.result.user_data = temp_user;
          this.resp = temp;
          this.active_user = this.resp.result.total_user;
          this.remaining_users = this.resp.result.remaining_total_user;
        }
      )
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload)
  }

  handleFileInput1(files: FileList) {
    this.logoToUpload = files.item(0);
  }

  uploadlogo(){
    if(this.logoToUpload==null)
    {
      alert("Please uplaod logo.");
      return false;
    }
    this.showProgress=true;
    let formData = new FormData();
    formData.append('logo_file', this.logoToUpload);
    formData.append('user_id', this.userid);
    console.log("csv",this.logoToUpload);
    formData.append('device', this.helperservice.dev);
    formData.append('device_unique', this.helperservice.browserversion);
    formData.append('qid', this.helperservice.qid);
    
    const md5object1 = new Md5();
    const digest1 = (md5object1.appendStr(environment.apiToken + this.userid).end());
    const create_user_api_url = environment.url + "/upload_corpo_logo?digest=" + digest1;
    this.http.post(create_user_api_url, formData)
      .subscribe(
        val => {
          this.progressVal=100;
          //this.showProgress=false;
          this.resp = JSON.parse(JSON.stringify(val));
          console.log(this.resp);
          //this.successmsg = "CSV uploaded successfully.";
          if(this.resp.errorCode==0)
          {
            localStorage.setItem('err_msg', "Logo Uploaded Successfully.");
          }
          else 
          {
            localStorage.setItem('err_msg', this.resp.errorMessage);
          }
          setTimeout(() => {
            location.href='/account/users-management';
           }, 2000);
        }
      )
    
  }

  importcsv() {
    
    if(this.fileToUpload==null)
    {
      alert("Please uplaod CSV.");
      return false;
    }
    this.showProgress=true;
    let formData = new FormData();
    console.log(this.fileToUpload);

    formData.append('csv_file', this.fileToUpload);
    formData.append('user_id', this.userid);
    formData.append('device', this.helperservice.dev);
    formData.append('device_unique', this.helperservice.browserversion);
    formData.append('qid', this.helperservice.qid);

    // let param1 = {
    //   user_id:  this.userid,
    // }
    const md5object1 = new Md5();
    const digest1 = (md5object1.appendStr(environment.apiToken + this.userid).end());
    const create_user_api_url = environment.url + "/import_corpo_user_by_csv?digest=" + digest1;
    this.http.post(create_user_api_url, formData)
      .subscribe(
        val => {
          this.progressVal=100;
          //this.showProgress=false;
          this.resp = JSON.parse(JSON.stringify(val));
          // console.log(this.resp);
          //this.successmsg = "CSV uploaded successfully.";
          if(this.resp.errorCode==0)
          {
            localStorage.setItem('err_msg', "Upload Successful.");
          }
          else 
          {
            localStorage.setItem('err_msg', this.resp.errorMessage);
          }
          setTimeout(() => {
            location.href='/account/users-management';
           }, 2000);
        }
      )
  }


  // selects(){  
  //     let ele=document.getElementsByClassName('chk');
  //     // var ele=document.getElementsByName('chk');  
  //     for(var i=0; i<ele.length; i++){  
  //         if(ele[i].type=='checkbox')  
  //             ele[i].checked=true;  
  //     }  

  favdelid = '';
  ondelete1() {
    if (this.favdelid != '') {
      let param = {
        user_id: this.userid,
        delete_user_id: this.favdelid,
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid
      }
      const md5object = new Md5();
      const digest = (md5object.appendStr(environment.apiToken + this.userid).end());
      const create_user_api_url = environment.url + "/delete_corpo_user?digest=" + digest;
      this.http.post(create_user_api_url, JSON.stringify(param))
        .subscribe(
          val => {
            this.resp = JSON.parse(JSON.stringify(val));
            window.location = this.currentUrl;
          }

        )
    }
  }

  ondelete(id) {
    this.favdelid = id;
    let ele: HTMLElement = document.getElementById('popuplnk') as HTMLElement;
    ele.click();
  }

  activate(id) {
    this.favdelid = id;
    let ele: HTMLElement = document.getElementById('popuplnkact') as HTMLElement;
    ele.click();
  }

  activate1() {
    if (this.favdelid != '') {
      let param = {
        user_id: this.favdelid,
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid
      }
      const md5object = new Md5();
      const digest = (md5object.appendStr(environment.apiToken + this.favdelid).end());
      const create_user_api_url = environment.url + "/sent_activation_link?digest=" + digest;
      this.http.post(create_user_api_url, JSON.stringify(param))
        .subscribe(
          val => {
            this.resp = JSON.parse(JSON.stringify(val));
            // console.log(this.resp);
            window.location.href = this.currentUrl;
          }
        )
    }
  }

  checkcheckBox() {
    let length = 0;
    let data = this.resp.result.user_data;

    for (var i = 0; i < data.length; i++) {
      if (data[i].isSelected == true) {
        length = 1;
        break;
      }
    }
    console.log(length);
    if (length == 1) {
      let ele: HTMLElement = document.getElementById('popuplnkaccount') as HTMLElement;
      ele.click();
    }
    else {
      this.successmsg = "Please select user to send activation link."
    }
  }

  // }

  // onEdit(id)
  // {
  //   let param = {
  //     user_id:this.userid,
  //     individual_user_id:id
  //   }
  //   const md5object1 = new Md5();
  //   const digest1= (md5object1.appendStr(environment.apiToken+this.userid).end());
  //   const create_user_api_url = environment.url+"/get_corpo_individual_user?digest="+digest1;
  //   this.http.post(create_user_api_url, JSON.stringify(param))
  //       .subscribe(
  //         val => {
  //           this.resp = JSON.parse(JSON.stringify(val));
  //           this.router.navigate(['/account/user']);
  //         }
  //       )
  // }
  onTableDataChange(event) {
    this.page = event;
    this.masterSelected = false;
  }
  onTableSizeChange(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.masterSelected = false;
  }
  // handleFileSelect(evt) {
  //   var files = evt.target.files; // FileList object
  //   var file = files[0];
  //   console.log(file);

  //   // var reader = new FileReader();
  //   // reader.readAsText(file);
  //   // reader.onload = (event: any) => {
  //   //   var csv = event.target.result; // Content of CSV file
  //   // }
  // }

  // public changeListener(files: FileList){                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
  //   console.log(files);                                                                                                                                                                                                                                                                                                                                                                                     
  //   if(files && files.length > 0) {
  //      let file : File = files.item(0); 
  //        console.log(file.name);
  //        console.log(file.size);
  //        console.log(file.type);
  //        console.log(file)
  //        let reader: FileReader = new FileReader();
  //        reader.readAsText(file);
  //        reader.onload = (e) => {
  //           let csv: string = reader.result as string;
  //           console.log(csv);
  //        }
  //     }
  //   }



}
