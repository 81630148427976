import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Loginsession } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  deviceInfo = null;
  devicetype = 'desktop';
  dev;
  browserversion;
  loginresponse: Loginsession;
  qid = '';
  constructor(private deviceService: DeviceDetectorService) { 
    this.epicFunction();
    if (localStorage.getItem('currentUser')) {
      this.loginresponse = JSON.parse(localStorage.getItem('currentUser'));
      this.qid = this.loginresponse.result.userid;
      // console.log(this.userid);
      // this.extsessionid=  this.login_user_session;
    }
  }
  

  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
    if(isMobile)
    {
      this.devicetype = "mobile";
    }
    else if(isTablet)
    {
      this.devicetype = "tablet";
    }
    else
    {
      this.devicetype= "desktop";
    }
    let os = this.deviceInfo.os;
    
    this.dev = this.devicetype + os;
    

    this.browserversion = this.deviceInfo.browser_version;

    console.log(this.browserversion);
  }
}
