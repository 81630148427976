import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../_services';
import { Loginsession } from './../../_models'; 
import { Router, ActivatedRoute, Params } from '@angular/router';

import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5'; 
import { HttpClient } from '@angular/common/http';
import { Series,Season,Seriesitem,SeasonItem,Seriesitemseasonsitems,SeasonItems,Seasonlist } from './../../_models';
import { HelperService } from '../../_services/helper.service';  

@Component({
  selector: 'app-episode',
  templateUrl: './episode.component.html',
  styleUrls: ['./episode.component.css']
})
export class EpisodeComponent implements OnInit {

  private login_user_session:string;
  isLoggedIn = false; 
  private login_user:Loginsession;
   
  
  routeParams: Params; 
  queryParams: Params;
 
  loginresponse: Loginsession; 
  token; 
  constructor( 
    protected route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private helperservice: HelperService) {
    
     if(localStorage.getItem('currentUser')){
        this.loginresponse = JSON.parse(localStorage.getItem('currentUser')); 
        const md5 = new Md5();
        this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end()); 
        this.login_user_session = this.loginresponse.result.session; 
        this.isLoggedIn = true;
     }
    // this.getRouteParams(); 
 

  }
   
   // Store parameter values on URL changes
    
   getRouteParams() { 
      // Route parameters
      this.activatedRoute.params.subscribe( (params: Params) => {
          this.routeParams = params;
      });

      // URL query parameters
      this.activatedRoute.queryParams.subscribe( (params: Params) => {
          this.queryParams = params;
      });
  }
  
  is_subscription; 
  subscription;
  season_id;
  series_id;
  episode_id;
  episode_data;
  currentUrl;
  ngOnInit() {
    
     
 

      /* Get series item */ 
      this.getRouteParams(); 
     
      this.series_id = this.routeParams.series_id;
      this.season_id = this.routeParams.season_id;
      this.episode_id = this.routeParams.episode_id;

      if( this.series_id <= 0 || this.season_id <= 0 || this.episode_id <= 0 ) {
        this.router.navigate(['/series-detail/'+this.series_id]);
      } 
      

      const md5object = new Md5();
      const digest= (md5object.appendStr(environment.apiToken + this.episode_id).end()); 
      const api_url = environment.url+"/getepisode_detail?digest="+digest+"&sid="+this.series_id+"&seid="+this.season_id+"&eid="+this.episode_id;
      const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
      this.http.get(api_url + extra_api_url)
      .subscribe(
          val => {    
            this.episode_data = JSON.parse(JSON.stringify(val)); 
            
          },
          error => {
              console.warn("This line is never called ",error);
          } 
      );  
     /* Get season list end */
      this.currentUrl=this.router.url;
      this.currentUrl=this.currentUrl.replace("#open-modal","");
      this.currentUrl=this.currentUrl.replace("#close","");
      console.log(this.currentUrl);
      
      if (this.isLoggedIn) {
      const md5object2 = new Md5();
      const digest2 = (md5object2.appendStr(environment.apiToken + this.loginresponse.result.userid).end());
      const api_url2 = environment.url + "/check_user_subscription?digest=" + digest2;
      this.http.post(api_url2, JSON.stringify({ user_id: this.loginresponse.result.userid,device: this.helperservice.dev,device_unique: this.helperservice.browserversion,qid: this.helperservice.qid }))
        .subscribe(
          val => {
            this.subscription = JSON.parse(JSON.stringify(val));
            this.is_subscription = this.subscription.result.is_subscription;
            // this.is_subscription=2;
          }
        );
    }

  } 

  slideConfig_editor = {
    "slidesToShow": 5, 
    "slidesToScroll": 4,
    "nextArrow":"<div style='top:-79px' class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div style='top:-79px' class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":false,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false, 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  };

  slickInit(e) {
    console.log('slick initialized');
  }

}
