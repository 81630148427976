import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Series } from './../../_models'; 
import { Loginsession } from './../../_models'; 
import { HelperService } from '../../_services/helper.service';

@Component({
  selector: 'app-allseries',
  templateUrl: './allseries.component.html',
  styleUrls: ['./allseries.component.css']
})
export class AllseriesComponent implements OnInit {
    
  seriesresponse: Series; 
  loginresponse: Loginsession;
  login_user_session="";
  token;
  series; 
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private helperservice: HelperService) {
    
     if(localStorage.getItem('currentUser')){
        this.loginresponse = JSON.parse(localStorage.getItem('currentUser')); 
        const md5 = new Md5();
        this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end()); 
        this.login_user_session = this.loginresponse.result.session; 
     }
  }

  slides = [
    {img: "assets/images/banner-pic.jpg"},
    {img: "assets/images/banner-pic.jpg"},
    {img: "assets/images/banner-pic.jpg"},
    {img: "assets/images/banner-pic.jpg"},
    {img: "assets/images/banner-pic.jpg"},
    {img: "assets/images/banner-pic.jpg"}, 
  ];

  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'></div>",
    "prevArrow":"<div class='nav-btn prev-slide'></div>",
    "dots":false,
    "arrows":true,
    "infinite":true
  };

  addSlide() {
    this.slides.push({img: "assets/images/banner-pic.jpg"})
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }
   
  
  series_count = 0;
  series_data;
  series_data_temp;
  hide_button=true;
  ngOnInit() {  

    const md5object = new Md5();
    const password_digest= (md5object.appendStr(environment.apiToken).end()); 

    const create_api_url = environment.url+"/getseries_list?digest="+password_digest;
    const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
    this.http.get(create_api_url + extra_api_url)
    .subscribe(
        val => {    
          this.series_data = JSON.parse(JSON.stringify(val));  
          this.series_data = this.series_data.result;
        }
    );  
  }

  loadMore(e) {
    const md5object = new Md5();
    const password_digest= (md5object.appendStr(environment.apiToken).end()); 
    this.series_count = this.series_count + 1;
    const create_api_url = environment.url+"/getseries_list?digest="+password_digest+"&li="+this.series_count;
    const extra_api_url1 = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
    this.http.get(create_api_url + extra_api_url1)
    .subscribe(
        val => {    
          this.series_data_temp = JSON.parse(JSON.stringify(val));  
          this.series_data = this.series_data.concat(this.series_data_temp.result);
          if(this.series_data_temp.result.length <= 0)
          this.hide_button = false;
        }
    ); 
  }
}
