import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmspageRoutingModule } from './cmspage-routing.module';
import { PageComponent } from './page/page.component';
import { ResubscribeComponent } from './resubscribe/resubscribe.component'; 

@NgModule({
  declarations: [PageComponent, ResubscribeComponent],  
  imports: [
    CommonModule,
    CmspageRoutingModule
  ]
})
export class CmspageModule { }
