import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mainmovies',
  templateUrl: './mainmovies.component.html',
  styleUrls: ['./mainmovies.component.css']
})
export class MainmoviesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
