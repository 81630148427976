import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { Loginsession,Sportscategory,Sportscategoryitemroot,Sportscategoryresult } from './../_models';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Md5 } from 'ts-md5/dist/md5';
import { HelperService } from '../_services/helper.service';
@Component({
  selector: 'app-sports',
  templateUrl: './sports.component.html',
  styleUrls: ['./sports.component.css']
})
export class SportsComponent implements OnInit {

  public apiData;
  liveurl: SafeResourceUrl;
  slider_result;
  loginresponse: Loginsession;
  private login_user_session: string;
  isLoggedIn = false;
  showliveurl  = false;
  token;
  ob_sportscategory:Sportscategoryresult;
  ob_sportscategory_items:Sportscategoryitemroot;

  constructor(public sanitizer: DomSanitizer,public http:HttpClient, private helperservice: HelperService) {
    if (localStorage.getItem('currentUser')) {
      this.loginresponse = JSON.parse(localStorage.getItem('currentUser'));
      const md5 = new Md5();
      this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end());
      this.login_user_session = this.loginresponse.result.session;
      this.isLoggedIn = true;
    }
   }

  ngOnInit() {
    const create_api_url = environment.url+"/get_sport_live_url";
    const extra_api_url = "?qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
    this.http.get(create_api_url + extra_api_url)
    .subscribe(
        val => { 
         this.apiData = JSON.parse(JSON.stringify(val));  
         this.liveurl= this.sanitizer.bypassSecurityTrustResourceUrl(this.apiData.live_url+"?autoplay=1&mute=1");  
          this.showliveurl = this.apiData.show_live_url=='Yes'?true:false;
        }
    ); 


    let paramhomecategory = { 
      "pid": 1,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    };
    const md5object2 = new Md5();
    const password_digest= (md5object2.appendStr(environment.apiToken).end())
    const sports_categories = environment.url+"/getsportspage_category?digest="+password_digest;
    this.http.post(sports_categories,JSON.stringify(paramhomecategory))
    .subscribe(
      val=>{
        this.ob_sportscategory = JSON.parse(JSON.stringify(val));
        for (let i = 0; i < this.ob_sportscategory.result.length; i++) {
          console.warn( this.ob_sportscategory.result[i].name, this.ob_sportscategory.result[i].genreid);
          //----------------------
          // get home page category items 
            let paramhomecategoryitems = { 
              "gid": this.ob_sportscategory.result[i].genreid,
              device: this.helperservice.dev,
              device_unique: this.helperservice.browserversion,
              qid: this.helperservice.qid
            };
            const md5object1 = new Md5();
            const password_digest1= (md5object1.appendStr(environment.apiToken+this.ob_sportscategory.result[i].genreid).end()); 

            const home_categoriesitems = environment.url+"/getsports_genrevideo?gid="+this.ob_sportscategory.result[i].genreid+"&digest="+password_digest1;
            this.http.post(home_categoriesitems,JSON.stringify(paramhomecategoryitems))
            .subscribe(
                val => {   
                  //console.log(val);
                  this.ob_sportscategory_items = JSON.parse(JSON.stringify(val));
                  this.ob_sportscategory.result[i].sportscategoryitem = this.ob_sportscategory_items.result;
                  // console.log("HELLO",this.ob_sportscategory_items.result);
                  //   this.ob_homevideo = JSON.parse(JSON.stringify(val));  
                }
            );
          // get home page category items end
          //----------------------
        }
      }
    )



    const md5object1 = new Md5();
    const digest2= (md5object1.appendStr(environment.apiToken).end()); 
    const create_user_api_url2 = environment.url+"/get_released_movies?digest="+digest2;
    const extra_api_url1 = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion;
    this.http.get(create_user_api_url2 + extra_api_url1)
    .subscribe(
      val=>{
        this.slider_result = JSON.parse(JSON.stringify(val));
      }
    )


  }

  slideConfig_editor2 = {
    "slidesToShow": 6, 
    "slidesToScroll": 4,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":false,
    "autoplay":false,
    // "initialSlide":0, 
    "swipe":false,
    // "useTransform":true,
  "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false, 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite:false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite:false,
        }
      } 
    ]
  };
  
  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {
    console.log('afterChange');
  }
  
  beforeChange(e) {
    console.log('beforeChange');
  }

}
