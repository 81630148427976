import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { Router, ActivatedRoute,Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService, UserService, AuthenticationService } from './../../_services';
import { environment } from './../../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { first } from 'rxjs/operators';
import { Userdetail,Packagedetail } from '../../_models';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { HelperService } from '../../_services/helper.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {


  onFocusEvent(event: any){
    console.log(event);
    if(event.target.type=="text"){
      event.target.type="date";
    };

  }
  gender='M';
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  routeParams:Params;
  queryParams:Params;
  loginmessage: string;
  signupres:Userdetail;
  packagedetail:Packagedetail;
  plan_price="";
  plan_currency="";
  plan_id="";
  plan_name="";
  showloginbtn=false;
  coupon_valid=false;
  code = "";
  coupon_response;
  error_type ="red";
  code1="";
  
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private userService: UserService,
    private http: HttpClient,
    private helperservice: HelperService) { 

      this.getRouteParams(); 

    }
    url_param = 1;
  ngOnInit() {

      
      if(localStorage.getItem('couponcode'))
      {
        this.code=localStorage.getItem('couponcode');
        localStorage.removeItem('couponcode');
      }else{
        this.code='';
      }
      
      //this.url_param = parseInt(this.routeParams.id);
      this.registerForm = this.formBuilder.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],  
          email: ['',Validators.required],
          phone: ['', Validators.required],
          password: ['', [Validators.required, Validators.minLength(6)]], 
          cnfpassword: ['', Validators.required],
          age:['',Validators.required],
          couponcode:[''],
          gender:[''],
          chktermofservice: ['', Validators.required],
          clientId: 33,
          digest : ''
      });
      
      
       
  }

  
   // Store parameter values on URL changes
   getRouteParams() {

      // Route parameters
      this.activatedRoute.params.subscribe( params => {
          this.routeParams = params;
      });

      // URL query parameters
      this.activatedRoute.queryParams.subscribe( params => {
          this.queryParams = params;
      });
  }

  get f() { return this.registerForm.controls; }
  userid;  
  resmessage;
  emailerror = '';
  passworderror = '';
  usernameerror = '';
  termcondition = '';
  couponerror='';

 
  
  isEmail(search:string):boolean {
      let  serchfind:boolean; 
      let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      serchfind = regexp.test(search); 
      return serchfind
  }

  onSubmit() {
     
  this.submitted = true;  
 
  if(this.registerForm.value.email != '') {
      if (!this.isEmail(this.registerForm.value.email)) { 
        this.emailerror = 'Email must be a valid email address';
        return;
      } else {
        this.emailerror = '';
      }
  } 


  if(this.registerForm.value.password != '') {
      if ( ( this.registerForm.value.password == '' || this.registerForm.value.cnfpassword == '' || this.registerForm.value.cnfpassword != this.registerForm.value.password ) ) {
        this.passworderror = 'Confirm password must be matched with password.';
        return;
      } else {
        this.passworderror = '';
      }
  }

  
  if(!this.registerForm.value.chktermofservice) {
    this.termcondition = 'Please confirm the terms of service.';
  } else {
    this.termcondition = '';
  }
  

  if (this.registerForm.invalid) {
    return;
  } 
  if (this.registerForm.value.phone == '') {
    return;
  } 

  if(this.coupon_valid==false && this.registerForm.value.couponcode!="")
  {
    this.couponerror ="Please validate your coupon code."
    return;
  }

  if(this.coupon_valid==true)
  {
    if(this.code1!=this.registerForm.value.couponcode){
      this.couponerror ="Please validate your coupon code."
      this.error_type="red";
       return;
    }
  }
  
  this.getRouteParams();   
  const md5 = new Md5(); 
  const digest = (md5.appendStr(environment.apiToken + this.registerForm.value.email).end());
  this.registerForm.value.digest = digest; 
  this.loading = true; 

  
  
  this.userService.registerDirect(this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => { 
             
            this.signupres = JSON.parse(JSON.stringify(data));
            if(parseInt(this.signupres.errorCode)==133){
              this.showloginbtn=true;
            }
            if(parseInt(this.signupres.errorCode)==0){ 

            //  if(this.routeParams.id == 1 || this.routeParams.id == '1')
              //  this.router.navigate(['/welcome']); 

              this.checkCountry();
              return; 
          
               const md5 = new Md5(); 
               const digest = (md5.appendStr(environment.apiToken+this.routeParams.id).end()); 
               const create_api_url = environment.url+"/getSubscriptionPlanDetails?digest="+digest+"&pid="+this.routeParams.id;  
               
               if( this.routeParams.id == 9991 ) {

                 this.router.navigate(['/welcome']);  

               } else {
                  const param = [];
                    this.http.post(create_api_url,JSON.stringify(param)).subscribe(
                      val => {    
                          this.packagedetail = JSON.parse(JSON.stringify(val));
                          if(parseInt(this.packagedetail.errorCode) == 0 ){ 
                                this.userid = this.signupres.result.userId;
                                this.plan_price=this.packagedetail.result.plan_price;
                                this.plan_currency=this.packagedetail.result.plan_currency;
                                this.plan_id=this.packagedetail.result.plan_id;
                                this.plan_name=this.packagedetail.result.plan_name;
                          } else {
                              this.resmessage = environment.error.getSubscriptionPlanDetails[parseInt(this.packagedetail.errorCode)]; 
                          }
                      }
                  );
                  
               } 
 
            } else {
              this.resmessage = environment.error.createUser[parseInt(this.signupres.errorCode)]; 
              this.loading = false; 
            } 
        },
        error => {
            this.alertService.error(error);
            this.loading = false;
        });


 
  }

  onSearchChange(searchValue: string): void { 
    this.code = searchValue;
  }

  function_apply(){

    if(this.registerForm.value.couponcode==""){
      this.couponerror = 'Please Enter Coupon Code.';
    }else {
      this.couponerror = '';
    }
    
    this.submitted = true;  
 
  if(this.registerForm.value.email != '') {
      if (!this.isEmail(this.registerForm.value.email)) { 
        this.emailerror = 'Email must be a valid email address';
        return;
      } else {
        this.emailerror = '';
      }
  } 


  if(this.registerForm.value.password != '') {
      if ( ( this.registerForm.value.password == '' || this.registerForm.value.cnfpassword == '' || this.registerForm.value.cnfpassword != this.registerForm.value.password ) ) {
        this.passworderror = 'Confirm password must be matched with password.';
        return;
      } else {
        this.passworderror = '';
      }
  }

  
  if(!this.registerForm.value.chktermofservice) {
    this.termcondition = 'Please confirm the terms of service.';
    return;
  } else {
    this.termcondition = '';
  }
  

  if (this.registerForm.invalid) {
    return;
  } 
  if (this.registerForm.value.phone == '') {
    return;
  }

  

  const md5 = new Md5(); 
  const digest = (md5.appendStr(environment.apiToken+this.routeParams.id).end()); 
  const create_api_url = environment.url+"/checkcoupon?digest="+digest+"&pid="+this.routeParams.id+"&cc="+this.registerForm.value.couponcode;
  const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
  this.http.get(create_api_url + extra_api_url)
  .subscribe(
  val=>{
    this.coupon_response = JSON.parse(JSON.stringify(val));
    if(this.coupon_response.errorCode==0)
    {
      this.coupon_valid=true;
      this.couponerror = this.coupon_response.errorMessage;
      this.error_type="green";
      this.code1 = this.registerForm.value.couponcode;
    }
    else
    {
      this.coupon_valid=false;
      this.couponerror = this.coupon_response.errorMessage;
      this.error_type="red";
    }
  }
  )
}

  payStackPayment() {

    const md5 = new Md5(); 
    const digest = (md5.appendStr(environment.apiToken+this.routeParams.id).end()); 
    const create_api_url = environment.url+"/getSubscriptionPlanDetails?digest="+digest+"&pid="+this.routeParams.id+"&cc="+this.registerForm.value.couponcode;  
    
   /*  if( this.routeParams.id == 1 ) {

      this.router.navigate(['/welcome']);  

    } else { */
        let param = {
        "pid":this.routeParams.id,
        "cc":this.registerForm.value.couponcode,
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid 
        };
         this.http.post(create_api_url,JSON.stringify(param)).subscribe(
           val => {    
               this.packagedetail = JSON.parse(JSON.stringify(val));
               if(parseInt(this.packagedetail.errorCode) == 0 ){ 
                     this.userid = this.signupres.result.userId;
                     this.plan_price=this.packagedetail.result.backend_price;
                     this.plan_currency=this.packagedetail.result.backend_currency;
                     this.plan_id=this.packagedetail.result.plan_id;
                     this.plan_name=this.packagedetail.result.plan_name;
               } else {
                 this.resmessage = environment.error.getSubscriptionPlanDetails[parseInt(this.packagedetail.errorCode)]; 
               }
           }
       );
       
    

  }

  checkCountry() {
          
        const md5 = new Md5();
        const digest = (md5.appendStr(environment.apiToken).end()); 
 
        const create_user_api_url = environment.url+"/getcountry_detail?digest="+digest;  
        
        let param = { device: this.helperservice.dev,
          device_unique: this.helperservice.browserversion,
          qid: this.helperservice.qid
        }; 
        this.http.post(create_user_api_url,JSON.stringify(param)).subscribe(
            val => {     
                this.packagedetail = JSON.parse(JSON.stringify(val)); 
                if( this.packagedetail.result.country == 'NG' ) {
                  this.payStackPayment();
                } else {
                  this.payStackPayment();
                  // if(  this.signupres.result.userId )
                  // this.router.navigate(['/payment/'+this.routeParams.id+'/'+this.signupres.result.userId]);
                } 
            },
            error => {
                console.warn("This line is never called ",error);
            },
            () => console.warn("registration process completed...")
        ); 
  }

  redirect()
  {
    window.location.href="/login"
  }

}
 
 