import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { HelperService } from '../../_services/helper.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {

  constructor( private http: HttpClient,private helperservice: HelperService) { }
  article_data;
  data_temp;
  hide_button=true;
  limit = 0;
  ngOnInit() {

    const md5object = new Md5();
    const password_digest= (md5object.appendStr(environment.apiToken).end()); 

    const create_api_url = environment.url+"/getarticle_list?digest="+password_digest;
    const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
    this.http.get(create_api_url + extra_api_url)
    .subscribe(
        val => {    
          this.article_data = JSON.parse(JSON.stringify(val));  
          this.article_data = this.article_data.result;
        }
    );

    
  }

  loadMore(e) {
     
    const md5object = new Md5();
    const password_digest= (md5object.appendStr(environment.apiToken).end());  
    this.limit = this.limit + 1;
    const create_api_url = environment.url+"/getarticle_list?digest="+password_digest+"&li="+this.limit;
    const extra_api_url1 = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
    this.http.get(create_api_url + extra_api_url1)
    .subscribe(
        val => {    
          this.data_temp = JSON.parse(JSON.stringify(val));  
          this.article_data = this.article_data.concat(this.data_temp.result);
          if(this.data_temp.result.length <= 0)
           this.hide_button = false;
        }
    );

  }

}
