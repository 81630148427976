import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { HttpClient } from '@angular/common/http';
import { AlertService, AuthenticationService } from '../../_services';
import { Userdetail } from '../../_models';
import { HelperService } from '../../_services/helper.service'; 

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit { 
    ForgotPasswordForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    errormessage: string;
    loginresponse: Userdetail;
    resmessage="";
    successmessage="";
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private helperservice: HelperService
  ) {
    if (this.authenticationService.currentUserValue) {
        this.router.navigate(['/login']);
    } 
   }

   ngOnInit() {
        this.ForgotPasswordForm = this.formBuilder.group({
            username: ['', Validators.required] 
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.ForgotPasswordForm.controls; }

    onSubmit() {
        this.submitted = true;
 

        // stop here if form is invalid
        if (this.ForgotPasswordForm.invalid) {
            return;
        }

        this.loading = true;

        const login_error = [];

        login_error["113"]="Maximum number of sessions per user exceeded";
        login_error["118"]="Client doesn’t have access to portal";
        login_error["119"]="Client doesn’t have access to API Call";
        login_error["120"]="Digest error";
        login_error["121"]="Client id is not valid";
        login_error["122"]="Version does not exist";
        login_error["137"]="User not activated";
        login_error["203"]="Username or Password Wrong";
        login_error["204"]="Portal id is missing";
        login_error["218"]="Digest is missing";
        login_error["219"]="Client id is missing";
        login_error["220"]="Version is missing";
        login_error["221"]="Username is missing";
        login_error["222"]="Password is missing"; 
        login_error["123"]="Username Error";
        login_error["129"]="Email was not sent";
         
        const md5object = new Md5();
        const password_digest= (md5object.appendStr(environment.apiToken + this.f.username.value).end()); 
        let param = { 
            "username": this.f.username.value,
            device: this.helperservice.dev,
            device_unique: this.helperservice.browserversion,
            qid: this.helperservice.qid
        };
        const create_user_api_url = environment.url+"/forgottenPassword?username="+this.f.username.value+"&digest="+password_digest;
        this.http.post(create_user_api_url,JSON.stringify(param))
        .subscribe(
            val => {   
               this.loginresponse = JSON.parse(JSON.stringify(val)); 
               const mapped = Object.keys(val).map(key => ({type: key, value: val[key]})); 
               
               if(parseInt(mapped[0].value)==0){        
                    this.successmessage =  "An email has been sent to you with a reset password link, please click on the link in the email to set your new password. If you cannot find the email, please check your Spam / Junk folder.";
                    this.resmessage = "";
                    this.loading = false; 
                } else {
                   // this.resmessage = (login_error[parseInt(this.loginresponse.errorcode)]);  
                    this.resmessage = environment.error.forgottenPassword[mapped[0].value];
                    this.successmessage = ""; 
                    this.loading = false; 
                }  
            },
            error => {
                console.warn("This line is never called ",error);
            },
            () => console.warn("registration process completed...")
        );  
        
    }

}
