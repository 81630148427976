import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from './../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Loginsession } from './../_models';
import { Userdetail } from './../_models';
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  accountForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  resmessage= '';
  successmessage= '';
  loginresponse: Loginsession;
  userdetail: Userdetail;
  login_user_session = '';
  token;
  firstname  = "";
  lastname  = "";
  emailfld = "";
  username="";
  phone  = "";
  is_corporate_acc;
  role_type;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private helperservice: HelperService) {
      
    this.loginresponse = JSON.parse(localStorage.getItem('currentUser'));  
    const md5 = new Md5(); 
    this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end()); 
    this.login_user_session = this.loginresponse.result.session;
    this.is_corporate_acc = this.loginresponse.result.is_corporate_acc;
    this.role_type = this.loginresponse.result.role_type;

  }

  ngOnInit() {
      this.getUserData();
      this.accountForm = this.formBuilder.group({
          firstname: [this.firstname, Validators.required],
          lastname: [this.lastname, Validators.required], 
          email: [this.emailfld, Validators.required], 
          username: [this.username, Validators.required], 
          phone: [this.phone, Validators.required]
      });
     
  }

  getUserData(){ 

    let uparam = { 
      "extsessionid":this.login_user_session,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    };  

    const create_user_api_url = environment.url+"/getUserData?digest="+this.token;  
    this.http.post(create_user_api_url,JSON.stringify(uparam))
      .subscribe(
          val => {  
              this.userdetail =  JSON.parse(JSON.stringify(val));
              console.log(this.userdetail); 
              const mapped = Object.keys(val).map(key =>  ((key)=='username')?val[key]:"" );
              this.resmessage = environment.error.getUserData[mapped[0].value];
              this.firstname  = this.userdetail.result.first_name;
              this.lastname  = this.userdetail.result.last_name;  
              this.emailfld = this.userdetail.result.email;   
              this.username= this.userdetail.result.username;   
              this.phone  = this.userdetail.result.mobile; 
          },
          error => {
              console.warn("This line is never called ",error);
          }
      ); 
  }

  // convenience getter for easy access to form fields
  get f() { return this.accountForm.controls; }
  onSubmit() {
   
      this.submitted = true;
 
      // stop here if form is invalid
      // if (this.accountForm.invalid) {
         // return;
      // }  
      //if(this.f.firstname.value == "" || this.f.lastName.value == "" || this.f.phone.value == "")
        //return;
        
      this.loading = true; 
      const login_error = [];   
     /* login_error[118]="Client doesn't have access to portal"; 
      login_error[119]="Client doesn't have access to API Call"; 
      login_error[120]="Digest error"; 
      login_error[121]="Client id is not valid"; 
      login_error[122]="Version does not exist"; 
      login_error[127]="User id error"; 
      login_error[204]="Portal id is missing"; 
      login_error[218]="Digest is missing"; 
      login_error[219]="Client id is missing"; 
      login_error[220]="Version is missing"; 
      login_error[236]="User id is missing";  */
      
      let uparam = { 
          "firstName":this.f.firstname.value,
          "lastName":this.f.lastname.value,
          "first_name":this.f.firstname.value,
          "last_name":this.f.lastname.value, 
          "Phone":this.f.phone.value,
          "extsessionid":this.login_user_session,
          device: this.helperservice.dev,
            device_unique: this.helperservice.browserversion,
            qid: this.helperservice.qid
      }; 

      const create_user_api_url = environment.url+"/editUserDetails?digest="+this.token; 
      this.http.post(create_user_api_url,JSON.stringify(uparam))
      .subscribe(
          val => {  
              this.userdetail = JSON.parse(JSON.stringify(val));  
              if(parseInt(this.userdetail.errorcode)==0){     
                  this.successmessage = "Your profile updated successfully.";
                  this.loading = false; 
                  this.resmessage = "";
              } else { 
                  this.resmessage = environment.error.editUserDetails[parseInt((JSON.stringify(val)))];
                  this.successmessage = "";
                  this.loading = false; 
              }  
          },
          error => {
              console.warn("This line is never called ",error);
          },
          () => console.warn("registration process completed...")
      ); 

   }
}
