import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../_services'; 
import { ActivatedRoute, Params } from '@angular/router';
import { Loginsession,Homecategory,Homecategoryitemroot,Homecategoryresult } from './../_models'; 
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { HelperService } from '../_services/helper.service';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  
  private login_user_session:string;
  isLoggedIn = false;
  private login_user:Loginsession;

  constructor(private authenticationService: AuthenticationService, private http: HttpClient, private activatedRoute: ActivatedRoute,private helperservice: HelperService) { 
      if (this.authenticationService.currentUserValue) {
        this.login_user = JSON.parse(localStorage.getItem('currentUser')); 
        this.isLoggedIn = true;
        this.login_user_session = this.login_user.result.session;
      } 
   }

  video_url_1;
  ob_homevideo:any;
  fb_url;
  twitter_url;
  insta_url;
  ob_homecategory:Homecategoryresult;
  ob_homecategory_items:Homecategoryitemroot;
  slider_result;
  latest_slider_result;
  kids_slider_result;
  banner_result;
  single_banner_image=false;
  multiple_banner_image=false;
  ismobile_or_not=false;
  ngOnInit() {


    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      this.ismobile_or_not= true;
    }
    else{
      this.ismobile_or_not= false;
    }
    
    // new api start
    const md5object = new Md5();
    const password_digest= (md5object.appendStr(environment.apiToken).end()); 
    let param = { 
        "pid": 1,
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid
    };
    const create_user_api_url = environment.url+"/getHomePageBanner?digest="+password_digest;
    this.http.post(create_user_api_url,JSON.stringify(param))
    .subscribe(
        val => {   
          // console.log(val);
          this.ob_homevideo = JSON.parse(JSON.stringify(val));
          if (this.ob_homevideo.is_auto_slide == 1 ) {
            this.slideConfig2.autoplay = true;
            // this.slideConfig2.infinite = true;
          }
          else {
            this.slideConfig2.autoplay = false;
            // this.slideConfig2.infinite = false;
          }
          let auto_slide_time=parseInt(this.ob_homevideo.auto_slide_time)*1000;
           this.slideConfig2.autoplaySpeed=auto_slide_time;
        }
    );
    // new api end
      // slider Api
      
      const md5object1 = new Md5();
      const digest2= (md5object1.appendStr(environment.apiToken).end()); 
      const create_user_api_url2 = environment.url+"/get_released_movies?digest="+digest2;
      const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
      this.http.get(create_user_api_url2 + extra_api_url)
      .subscribe(
        val=>{
          this.slider_result = JSON.parse(JSON.stringify(val));
          this.slideConfig_editor2.slidesToShow = parseInt(this.slider_result.dis_num);
        }
      )

        // slider api done

        // banner or coursol display api start
        const md5object5 = new Md5();
        const digest5= (md5object5.appendStr(environment.apiToken).end()); 
        const create_user_api_url5 = environment.url+"/getPromoBanner?digest="+digest5;
        const extra_api_url1 = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
        this.http.get(create_user_api_url5 + extra_api_url1)
        .subscribe(
          val=>{
            let temp= JSON.parse(JSON.stringify(val));
            this.banner_result=temp.result;
            if(this.banner_result.length==1)
            {
              this.single_banner_image = true;
            }
            else if(this.banner_result.length>1)
            {
              this.single_banner_image = false;
              this.multiple_banner_image = true;
            }
            else
            {
              this.single_banner_image = false;
              this.multiple_banner_image = false;
            }
          }
        )

    // get home page category  
    let paramhomecategory = { 
        "pid": 1,
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid
    };
    const home_categories = environment.url+"/gethomepage_category_new?digest="+password_digest;
    this.http.post(home_categories,JSON.stringify(paramhomecategory))
    .subscribe(
        val => {   
          // console.log(val);
          this.ob_homecategory = JSON.parse(JSON.stringify(val));
          for (let i = 0; i < this.ob_homecategory.result.length; i++) {
            console.warn( this.ob_homecategory.result[i].name, this.ob_homecategory.result[i].genreid);
            //----------------------
            // get home page category items 
              if(this.ob_homecategory.result[i].genreid==32)
              {
                  const md5object3 = new Md5();
                  const digest3= (md5object3.appendStr(environment.apiToken).end()); 
                  const create_user_api_url3 = environment.url+"/getlatest_series_list?digest="+digest3;
                  const extra_api_url1 = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion;
                  this.http.get(create_user_api_url3 + extra_api_url1)
                  .subscribe(
                    val=>{
                      this.ob_homecategory_items = JSON.parse(JSON.stringify(val));
                      this.ob_homecategory.result[i].homecategoryitem = this.ob_homecategory_items.result;
                      this.slideConfig_editor.slidesToShow = parseInt(this.ob_homecategory_items.dis_num);
                    }
                  )
              }
              else if(this.ob_homecategory.result[i].genreid==27)
              {
                const md5object4 = new Md5();
                const digest4= (md5object4.appendStr(environment.apiToken).end()); 
                const create_user_api_url4 = environment.url+"/getlatest_kids_series_list?digest="+digest4;
                const extra_api_url1 = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion;
                this.http.get(create_user_api_url4 + extra_api_url1)
                .subscribe(
                  val=>{
                    this.ob_homecategory_items = JSON.parse(JSON.stringify(val));
                    this.ob_homecategory.result[i].homecategoryitem = this.ob_homecategory_items.result;
                    this.slideConfig_editor.slidesToShow = parseInt(this.ob_homecategory_items.dis_num);
                  }
                )
              }
              else
              {
                let paramhomecategoryitems = { 
                  "gid": this.ob_homecategory.result[i].genreid,
                  device: this.helperservice.dev,
                  device_unique: this.helperservice.browserversion,
                  qid: this.helperservice.qid
                };
                const md5object1 = new Md5();
                const password_digest1= (md5object1.appendStr(environment.apiToken+this.ob_homecategory.result[i].genreid).end()); 
  
                const home_categoriesitems = environment.url+"/getmovies_genrevideo?gid="+this.ob_homecategory.result[i].genreid+"&digest="+password_digest1;
                this.http.post(home_categoriesitems,JSON.stringify(paramhomecategoryitems))
                .subscribe(
                    val => {   
                      //console.log(val);
                      this.ob_homecategory_items = JSON.parse(JSON.stringify(val));
                      this.ob_homecategory.result[i].homecategoryitem = this.ob_homecategory_items.result;
                      this.slideConfig_editor.slidesToShow = parseInt(this.ob_homecategory_items.dis_num);
                
                      //   this.ob_homevideo = JSON.parse(JSON.stringify(val));  
                    }
                );
              }
               
            // get home page category items end
            //----------------------
          }
       //   this.ob_homevideo = JSON.parse(JSON.stringify(val));  
        }
    );
    // get home page category end

  
    
    //this.video_url_1 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/maleficent1-HD.mp4/playlist.m3u8?extsessionid='+this.login_user_session;
   /* const api_url2 = "http://ariseplay.sass.works/index.php/getHomePageBanner";
    this.http.get(api_url2)
    .subscribe(  
        val => {      
          console.log(val);
           this.ob_homevideo = val;  
        }
    ) */

    this.fb_url = encodeURI(window.location.href); 
    this.twitter_url= encodeURI(window.location.href); 
    this.insta_url= encodeURI(window.location.href); 
  } 
    
  slides = [
    {id:1,title:"Finding The Sahara Movie", img: "assets/images/slider/card1.jpeg"},
    {id:2,title:"Finding The Sahara Movie", img: "assets/images/slider/card2.jpeg"},
    {id:3,title:"Finding The Sahara Movie 2", img: "assets/images/slider/card3.jpeg"},
    {id:4,title:"Finding The Sahara Movie", img: "assets/images/slider/card4.jpeg"},
   /* {id:5,title:"Finding The Sahara Movie", img: "assets/images/banner-pic.jpg"},
    {id:6,title:"Finding The Sahara Movie", img: "assets/images/banner-pic.jpg"}, */
  ];

  slideConfig_editor = {
    "slidesToShow": 4, 
    "slidesToScroll": 4,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":false,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite:false, 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite:false,
        }
      } 
    ]
  };

  slideConfig_editor2 = {
    "slidesToShow": 4, 
    "slidesToScroll": 4,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":false,
    "autoplay":false,
    // "initialSlide":0, 
    "swipe":false,
    // "useTransform":true,
  "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false, 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite:false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite:false,
        }
      } 
    ]
  };

  slideConfig_editor3 = {
    "slidesToShow": 3, 
    "slidesToScroll": 3,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":false,
    "autoplay":false,
    // "initialSlide":0, 
    "swipe":false,
    // "useTransform":true,
  "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false, 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite:false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite:false,
        }
      } 
    ]
  };

  slideConfig_editor4 = {
    "slidesToShow": 3, 
    "slidesToScroll": 3,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":false,
    "autoplay":false,
    // "initialSlide":0, 
    "swipe":false,
    // "useTransform":true,
  "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false, 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite:false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite:false,
        }
      } 
    ]
  };

   slideConfig2 = {
    "autoplay": false,
    "autoplaySpeed":2000,
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'></div>",
    "prevArrow":"<div class='nav-btn prev-slide'></div>",
    "dots":true,
    "arrows":true,
    "infinite":false,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1, 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  }; 

  topslideConfig = {
    "autoplay": false,
    "autoplaySpeed":2000,
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'></div>",
    "prevArrow":"<div class='nav-btn prev-slide'></div>",
    "dots":false,
    "arrows":true,
    "infinite":false,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1, 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  }; 

  slideConfig3 = {
    "slidesToShow": 4, 
    "slidesToScroll": 3,
    "nextArrow":"<div class='nav-btn next-slide'></div>",
    "prevArrow":"<div class='nav-btn prev-slide'></div>",
    "dots":true,
    "arrows":true,
    "infinite":true
  }; 
  
  slideConfig = {
    "slidesToShow": 4, 
    "slidesToScroll": 3,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true, 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  };

addSlide() {
  this.slides.push({id:1, title:"Finding The Sahara Movie", img: "assets/images/banner-pic.jpg"})
}

removeSlide() {
  this.slides.length = this.slides.length - 1;
}

slickInit(e) {
  // console.log('slick initialized');
}

breakpoint(e) {
  // console.log('breakpoint');
}

afterChange(e) {
  // console.log('afterChange');
}

beforeChange(e) {
  // console.log('beforeChange');
}


}
