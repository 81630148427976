import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {

  public apiData;
  eventurl: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer,public http:HttpClient,private helperservice: HelperService) { }

  ngOnInit() {
    
    const create_api_url = environment.url+"/get_live_url";
    const extra_api_url = "?qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;

    this.http.get(create_api_url + extra_api_url)
    .subscribe(
        val => { 
         this.apiData = JSON.parse(JSON.stringify(val));  
         this.eventurl= this.sanitizer.bypassSecurityTrustResourceUrl(this.apiData.live_url+"?autoplay=1&mute=1");  
          
        }
    ); 

  }

}
