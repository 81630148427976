import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { AuthenticationService } from './../../_services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HelperService } from '../../_services/helper.service';
@Component({
  selector: 'app-prpage',
  templateUrl: './prpage.component.html',
  styleUrls: ['./prpage.component.css']
})
export class PrpageComponent implements OnInit {
  routeParams;
  queryParams;
  isLoggedIn = false;
  articlevideourl: string = "";
  urlSafe: SafeResourceUrl;
  constructor(public sanitizer: DomSanitizer,private router: Router,private authenticationService: AuthenticationService, private http: HttpClient,private activatedRoute: ActivatedRoute,private helperservice: HelperService) { 
    this.getRouteParams(); 
    if (this.authenticationService.currentUserValue) {
          this.isLoggedIn = true;
    }
  }
  fb_url;
  twitter_url;
  insta_url;
  detail_api;
  itemid;
  flg_id = 1; 
  slideConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 3,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  };
  ngOnInit() {  

    this.getRouteParams(); 
    if( this.routeParams.id <= 0) {
      this.router.navigate(['/login']);
    } 

    const md5 = new Md5();
    const digest = (md5.appendStr(environment.apiToken).end()); 

    this.itemid = this.routeParams.id; 
    if(this.itemid > 0){
      const api_url2 = environment.url+"/getArticleDetail?digest="+digest+"&id="+this.itemid;
      const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
        //const api_url2 = "http://ariseplay.sass.works/index.php/api/JSONMOBILE/getArticleDetail?digest="+digest+"&id="+this.itemid;
        this.http.get(api_url2 + extra_api_url)
        .subscribe(  
            val => {      
              console.warn(val);
              this.detail_api = val;
              this.articlevideourl= this.detail_api.result.video_link ;
              this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.articlevideourl+"?autoplay=1&mute=1");  
            }
        ) 
    }

     
    this.fb_url = encodeURI(window.location.href); 
    this.twitter_url= encodeURI(window.location.href); 
    this.insta_url= encodeURI(window.location.href); 
  }

  
   // Store parameter values on URL changes
   getRouteParams() {

      // Route parameters
      this.activatedRoute.params.subscribe( params => {
          this.routeParams = params;
      });

      // URL query parameters
      this.activatedRoute.queryParams.subscribe( params => {
          this.queryParams = params;
      });
  }


}
