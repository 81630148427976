import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MiddleComponent } from './middle/middle.component';
import { RegisterComponent } from './guest/register/register.component';
import { LoginComponent } from './guest/login/login.component';
import { MoviesComponent } from './cmspage/movies/movies.component';
import { AccountComponent } from './account/account.component';
import { ResetpasswordComponent } from './account/resetpassword/resetpassword.component';
import { WelcomeComponent } from './account/welcome/welcome.component';
import { SignupComponent } from './guest/signup/signup.component'; 
import { ForgotpasswordComponent } from './guest/forgotpassword/forgotpassword.component'; 
import { AllseriesComponent } from './cmspage/allseries/allseries.component';  
import { SeriesitemComponent } from './cmspage/seriesitem/seriesitem.component'; 
import { GenredetailComponent } from './cmspage/genredetail/genredetail.component';
import { GenreComponent } from './cmspage/genre/genre.component';
import { PlaymovieComponent } from './cmspage/playmovie/playmovie.component'; 
import { GenrelistComponent } from './cmspage/genrelist/genrelist.component'; 
import { PrpageComponent } from './cmspage/prpage/prpage.component'; 
import { PlanComponent } from './cmspage/plan/plan.component'; 
import { PaywelcomeComponent } from './cmspage/welcome/welcome.component'; 
import { HistoryComponent } from './account/history/history.component';
import { DevicesComponent } from './account/devices/devices.component'; 

import { AudioComponent } from './cmspage/audio/audio.component'; 
import { ArticlesComponent } from './cmspage/articles/articles.component'; 

import { MainmoviesComponent } from './cmspage/mainmovies/mainmovies.component';
import { MainseriesComponent } from './cmspage/mainseries/mainseries.component'; 

import { TermsconditionsComponent } from './cmspage/termsconditions/termsconditions.component'; 
import { PrivacyComponent } from './cmspage/privacy/privacy.component'; 
import { PaymentComponent } from './cmspage/payment/payment.component'; 
import { EpisodeComponent } from './cmspage/episode/episode.component';
import { LiveComponent } from './live/live.component';
import { SearchComponent } from './search/search.component';
import { SupportComponent} from './support/support.component';
import { EventComponent} from './event/event.component';
import { FavouriteComponent } from './account/favourite/favourite.component';
import { UsersmanagementComponent } from './account/usersmanagement/usersmanagement.component';
import { AddedituserComponent } from './account/addedituser/addedituser.component';
import { EdituserComponent } from './account/edituser/edituser.component';
import { SubscriptiondetailsComponent } from './account/subscriptiondetails/subscriptiondetails.component';
import { ListpaymentComponent } from './account/listpayment/listpayment.component'
import { from } from 'rxjs';
import { ResubscribeComponent } from './cmspage/resubscribe/resubscribe.component';
import { AllkidsseriesComponent } from './cmspage/allkidsseries/allkidsseries.component';
import { SportsComponent } from './sports/sports.component'
import { SportslistComponent } from './sportslist/sportslist.component';
import { SportsdetailsComponent } from './sportsdetails/sportsdetails.component';
import { CompetitionruleComponent } from './competitionrule/competitionrule.component';
import { DemovideoComponent } from './demovideo/demovideo.component';
import { PinPasswordComponent } from './account/pinpassword/pinpassword.component';

const routes: Routes = [
  { path : '', component: MiddleComponent },
  { path : 'signup/:id', component: RegisterComponent },
  { path : 'search', component: SearchComponent },
  { path : 'search/:search', component: SearchComponent },
  { path : 'live', component: LiveComponent },
  { path : 'login', component: LoginComponent },
  { path : 'forgot-password', component: ForgotpasswordComponent },
  { path : 'view-movies', component: MoviesComponent },
  { path : 'account', component: AccountComponent },
  { path : 'account/welcome', component: WelcomeComponent }, 
  { path : 'account/resetpassword', component: ResetpasswordComponent }, 
  { path : 'old-signup/:id', component: SignupComponent }, 
  { path : 'series-detail/:id', component: SeriesitemComponent },
  { path : 'all-series', component: AllseriesComponent }, 
  { path : 'movie-detail/:id/:vid', component: GenredetailComponent },  
  { path : 'play-movie/:id', component: PlaymovieComponent },  
  { path : 'movies', component: GenrelistComponent },
  { path : 'genre/:id', component: GenreComponent }, 
  { path : 'article-detail/:id', component: PrpageComponent },
  { path : 'plan', component: PlanComponent }, 
  { path : 'account/history', component: HistoryComponent },
  { path : 'account/devices', component: DevicesComponent },
  { path : 'account/pinPassword', component: PinPasswordComponent },
  { path : 'welcome', component: PaywelcomeComponent }, 
  { path : 'articles', component: ArticlesComponent }, 
  { path : 'audio', component: AudioComponent }, 
  { path : 'arise-movies', component: MainmoviesComponent }, 
  { path : 'privacy', component: PrivacyComponent }, 
  { path : 'terms', component: TermsconditionsComponent }, 
  { path : 'payment/:id/:uid', component: PaymentComponent }, 
  { path : 'episode/:series_id/:season_id/:episode_id', component: EpisodeComponent }, 
  { path : 'help-centre', component: SupportComponent }, 
  { path : 'event', component: EventComponent }, 
  { path : 'account/favourite', component: FavouriteComponent },
  { path : 'account/users-management', component: UsersmanagementComponent },
  { path : 'account/user', component: AddedituserComponent },
  { path : 'account/edit/:id', component: EdituserComponent },
  { path : 'account/subscriptiondetails',component: SubscriptiondetailsComponent },
  { path : 'account/listpayment',component:ListpaymentComponent },
  { path : 'resubscribe',component:ResubscribeComponent},
  { path : 'all-kids-series', component: AllkidsseriesComponent } ,
  { path : 'sports', component: SportsComponent },
  { path : 'sportsgenre/:id', component: SportslistComponent },
  { path : 'sports-detail/:id/:vid', component: SportsdetailsComponent },
  { path : 'competition-rules',component:CompetitionruleComponent},
  { path : 'demovideo',component:DemovideoComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
