import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders, HttpErrorResponse   } from '@angular/common/http';
import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { AlertService, AuthenticationService } from '../../_services';
import { Loginsession } from '../../_models';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Packagedetail } from '../../_models';
import { HelperService } from '../../_services/helper.service';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {
  loginresponse: Loginsession;
  // private login_user:Loginsession;
  private login_user_session:string;
  token; 

  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private active:ActivatedRoute,
    private helperservice: HelperService
  ) {

    if(localStorage.getItem('currentUser')){
      this.loginresponse = JSON.parse(localStorage.getItem('currentUser')); 
      const md5object = new Md5();
      this.token = (md5object.appendStr(environment.apiToken + this.loginresponse.result.session).end()); 
      this.login_user_session = this.loginresponse.result.session;
      // let le = this.loginresponse.result.userid
      this.isLoggedIn = true;
      let uparam = { 
        "extsessionid":this.login_user_session,
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid
    }; 
      const user_api_url = environment.url+"/getUserData?digest="+this.token;  
       this.http.post(user_api_url,JSON.stringify(uparam))
         .subscribe(
            val => {  
               this.userdetail =  JSON.parse(JSON.stringify(val));
               console.log(this.userdetail);
               this.firstName=this.userdetail.result.first_name;  
               this.lastName=this.userdetail.result.last_name;
               this.email=this.userdetail.result.email; 
              // this.userid=this.userdetail.result.user_id;
            },
            error => {
                console.warn("This line is never called ",error);
            }
        ); 

   }




  }
  email
  lastName;
  userdetail;
  firstName;
  isLoggedIn = false;
  packagedetail:Packagedetail;
  planlist;
  signupurl="/signup/1";
  planclass = "";
  errmessage = "";
  plan_price="";
  plan_currency="";
  plan_id="";
  plan_name="";
  resmessage;
  userid;
  errorMessage;

  onplanclick(plan,event,obplan){
    this.signupurl="/signup/"+plan;
    this.planclass = plan; 
   }
  ngOnInit() {

      const md5 = new Md5();
      const digest = (md5.appendStr(environment.apiToken).end()); 

      const create_user_api_url = environment.url+"/getSubscriptionPlans?digest="+digest;  
      const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;  
      this.http.get(create_user_api_url + extra_api_url).subscribe(
        val => {
            this.packagedetail = JSON.parse(JSON.stringify(val));
            if(parseInt(this.packagedetail.errorCode)==0){ 
              this.planlist = JSON.parse(JSON.stringify(val));    
              this.planlist = this.planlist.result; 
            } else {
              this.errmessage = environment.error.getSubscriptionPlans[parseInt(this.packagedetail.errorCode)]; 
            }
        },
        error => {
            console.warn("This line is never called ",error);
        },
        () => console.warn("registration process completed...")
    ); 

    let coupon_code=this.active.snapshot.queryParamMap.get("cc");

    if(coupon_code){
      localStorage.setItem('couponcode', coupon_code);
    }
  }

  checkvalidation(){
    if(this.planclass=='')
    {
        this.errorMessage = "please select a plan.";
    }
    else
    {
      window.location.href=this.signupurl;
    }
  }

  payStackPayment() {
    const md5 = new Md5(); 
    const digest = (md5.appendStr(environment.apiToken+this.planclass).end()); 
    const create_api_url = environment.url+"/getSubscriptionPlanDetails?digest="+digest+"&pid="+this.planclass;  
        let param = {"pid":this.planclass,
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid
      };
         this.http.post(create_api_url,JSON.stringify(param)).subscribe(
           val => {    
               this.packagedetail = JSON.parse(JSON.stringify(val));
               if(parseInt(this.packagedetail.errorCode) == 0 ){ 
                    this.userid =this.loginresponse.result.userid;
                     this.plan_price=this.packagedetail.result.backend_price;
                     this.plan_currency=this.packagedetail.result.backend_currency;
                     this.plan_id=this.packagedetail.result.plan_id;
                     this.plan_name=this.packagedetail.result.plan_name;

               } else {
                 this.resmessage = environment.error.getSubscriptionPlanDetails[parseInt(this.packagedetail.errorCode)]; 
               }
           }
       );
  }
}
