import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { Loginsession } from './../../_models'; 
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HelperService } from '../../_services/helper.service';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
 
  isLoggedIn = false; 
  routeParams; 
  queryParams; 
  paymentresponse: Loginsession; 
  token;  
  login_user_session; 
  userid = "";

  card_number = '4242424242424242';
  exp_month = '021';
  exp_year = '2022';
  cvv = '123'; 
  submitted = false;
  global_error = '';

  cardForm: FormGroup;
 
  constructor( protected route: ActivatedRoute, private router: Router, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder,private http: HttpClient,private helperservice: HelperService) { 
 

  }

  ngOnInit() {
    this.getRouteParams();  
    this.cardForm = this.formBuilder.group({
        card_number: ['', Validators.required],
        expiry: ['', Validators.required],
        cvv: ['', Validators.required]
    });
  }

  get f() { return this.cardForm.controls; }

  // Store parameter values on URL changes
  getRouteParams() { 
      // Route parameters
      this.activatedRoute.params.subscribe( (params: Params) => {
          this.routeParams = params;
      });

      // URL query parameters
      this.activatedRoute.queryParams.subscribe( (params: Params) => {
          this.queryParams = params;
      });
  } 

  onSubmit() {

    this.submitted = true;
  
    
    // stop here if form is invalid
    if (this.cardForm.invalid) {
        return;
    }  

    this.card_number = this.f.card_number.value;
    let monthyear = this.f.expiry.value.split("/"); 
    if( monthyear.length > 1) {
      this.exp_month = monthyear[0];
      this.exp_year = monthyear[1];
    } else {
      let monthyear = this.f.expiry.value.split("-"); 
      if( monthyear.length > 1) {
        this.exp_month = monthyear[0];
        this.exp_year = monthyear[1];
      } else {
        this.global_error = "Please enter valid card details.";
        return;
      }
    }
 
    this.cvv =  this.f.cvv.value; 

    this.getRouteParams(); 
    let pid = this.routeParams.id;
    let uid = this.routeParams.uid;
     
    const md5 = new Md5();
    const digest = (md5.appendStr(environment.apiToken + uid).end()); 

    let uparam = { "uid":uid, "pid":pid, "card":this.card_number, "cvv": this.cvv, "exp_month": this.exp_month, "exp_year": this.exp_year,"ip": "192.168.0.1" ,"device": this.helperservice.dev,"device_unique": this.helperservice.browserversion,"qid": this.helperservice.qid
  }; 
     
    const user_api_url = environment.url+"/postpay_detail?uid="+uid+"&digest="+digest;  
    this.http.post(user_api_url, JSON.stringify(uparam))
      .subscribe(
          val => {  
              this.paymentresponse = JSON.parse(JSON.stringify(val)); 

              if( this.paymentresponse.errorMessage != "" ) {
                this.global_error = this.paymentresponse.errorMessage; 
              } else if ( this.paymentresponse.result.success  ) {
                this.router.navigate(['/welcome']);
              }  
          }
      );
  }
}