import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(public http:HttpClient,private helperservice: HelperService) { 
    
  }
  apiData:any;
  show_sports_menu= false;

  ngOnInit() {
    const create_api_url = environment.url+"/getSportsMenu";
    const extra_api_url1 = "?qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion;
    this.http.get(create_api_url + extra_api_url1)
    .subscribe(
        val => { 
          this.apiData = JSON.parse(JSON.stringify(val));  
          this.show_sports_menu = this.apiData.result.is_sport_menu_show == '1' ? true:false;
        }
    ); 

  }

}
