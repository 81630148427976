import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../_services';
import { Loginsession } from './../_models';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { environment } from './../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { HttpClient } from '@angular/common/http';
import { Series, Season, Seriesitem, SeasonItem } from './../_models';
import { HelperService } from '../_services/helper.service';
@Component({
  selector: 'app-sportsdetails',
  templateUrl: './sportsdetails.component.html',
  styleUrls: ['./sportsdetails.component.css']
})
export class SportsdetailsComponent implements OnInit {

  private login_user_session: string;
  isLoggedIn = false;
  private login_user: Loginsession;
  routeParams;
  queryParams;
  loginresponse: Loginsession;
  token;
  slides = [];
  currentUrl;
  // extsessionid;
  favourite;
  alreadymsg;
  userid;

  slideConfig_editor = {
    "slidesToShow": 4,
    "slidesToScroll": 4,
    "nextArrow": "<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow": "<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots": false,
    "arrows": true,
    "infinite": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  slideConfig_editor_video = {
    "slidesToShow": 7,
    "slidesToScroll": 4,
    "nextArrow": "<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow": "<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots": false,
    "arrows": true,
    "infinite": true
  };

  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private helperservice: HelperService) {

    if (localStorage.getItem('currentUser')) {
      this.loginresponse = JSON.parse(localStorage.getItem('currentUser'));
      const md5 = new Md5();
      this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end());
      this.login_user_session = this.loginresponse.result.session;
      // let le = this.loginresponse.result.userid
      this.isLoggedIn = true;
      this.userid = this.loginresponse.result.userid;
      // console.log(this.userid);
      // this.extsessionid=  this.login_user_session;
    }

  }

  // Store parameter values on URL changes
  getRouteParams() {

    // Route parameters
    this.activatedRoute.params.subscribe(params => {
      this.routeParams = params;
    });

    // URL query parameters
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;
    });
  }
  is_subscription;
  subscription;
  movie_id;
  video_detail;
  related_movie;
  successmsg;
  favouritemsg;
  errMsg;
  ngOnInit() {

    this.currentUrl = this.router.url;
    this.currentUrl = this.currentUrl.replace("#open-modal", "");
    this.currentUrl = this.currentUrl.replace("#close", "");
    console.log(this.currentUrl);


    let video_url_1 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/maleficent1-HD.mp4/playlist.m3u8?extsessionid=' + this.login_user_session;
    let video_url_2 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/abominable-HD.mp4/playlist.m3u8?extsessionid=' + this.login_user_session;
    let video_url_3 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/joker-HD.mp4/playlist.m3u8?extsessionid=' + this.login_user_session;
    let video_url_4 = "http://packageb.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageB/Programs/starwars_vod-25f-16x9-HD.mp4/playlist.m3u8?extsessionid=" + this.login_user_session;
    let video_url_5 = 'http://packageb.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageB/Programs/maleficient2_vod-25f-16x9-HD.mp4/playlist.m3u8?extsessionid=' + this.login_user_session;

    this.slides = [
      { id: "video_1", img: "assets/images/genre-detail-image-1.png", video: video_url_1, caption: "Ballad of Buster Scruggs 1 ", title: "Drama" },
      { id: "video_2", img: "assets/images/genre-detail-image-1.png", video: video_url_2, caption: "Ballad of Buster Scruggs 2", title: "Drama" },
      { id: "video_3", img: "assets/images/genre-detail-image-1.png", video: video_url_3, caption: "Ballad of Buster Scruggs 3", title: "Drama" },
      { id: "video_4", img: "assets/images/genre-detail-image-1.png", video: video_url_4, caption: "Ballad of Buster Scruggs 4", title: "Drama" },
      { id: "video_5", img: "assets/images/genre-detail-image-1.png", video: video_url_5, caption: "Ballad of Buster Scruggs 5", title: "Drama" },
      { id: "video_6", img: "assets/images/genre-detail-image-1.png", video: video_url_3, caption: "Ballad of Buster Scruggs 6", title: "Drama" },
      { id: "video_7", img: "assets/images/genre-detail-image-1.png", video: video_url_4, caption: "Ballad of Buster Scruggs 7", title: "Drama" },
      { id: "video_8", img: "assets/images/genre-detail-image-1.png", video: video_url_5, caption: "Ballad of Buster Scruggs 8", title: "Drama" },
    ];


    if (this.isLoggedIn) {
      const md5object2 = new Md5();
      const digest2 = (md5object2.appendStr(environment.apiToken + this.loginresponse.result.userid).end());
      const api_url2 = environment.url + "/check_user_subscription?digest=" + digest2;
      this.http.post(api_url2, JSON.stringify({ user_id: this.loginresponse.result.userid,
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid }))
        .subscribe(
          val => {
            this.subscription = JSON.parse(JSON.stringify(val));
            this.is_subscription = this.subscription.result.is_subscription;
            // this.is_subscription=2;
          }
        );
    }


    this.getRouteParams();
    const md5object = new Md5();
    const digest = (md5object.appendStr(environment.apiToken + this.routeParams.id).end());
    const api_url = environment.url + "/getsports_detail?digest=" + digest + "&mid=" + this.routeParams.id;
    const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
    this.http.get(api_url + extra_api_url)
      .subscribe(
        val => {
          this.video_detail = JSON.parse(JSON.stringify(val));
        }
      );


    const md5object1 = new Md5();
    const digest1 = (md5object1.appendStr(environment.apiToken + this.routeParams.vid).end());
    const api_url1 = environment.url + "/getrelatedsports_list?digest=" + digest1 + "&vid=" + this.routeParams.vid;
    const extra_api_url1 = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
    this.http.get(api_url1 + extra_api_url1)
      .subscribe(
        val => {
          this.related_movie = JSON.parse(JSON.stringify(val));
        }
      );






    /* Get series item * / 
    let channelId = 845; 
    this.series_id = 908; 
    const md5object = new Md5();
    const digest= (md5object.appendStr(environment.apiToken + this.series_id).end()); 
    const api_url = environment.apiMobUrl+"/getSeriesItems?version=2&clientId=33&portalid=288&digest="+digest+"&channelId="+channelId+"&seriesId="+this.series_id;
    this.http.get(api_url)
    .subscribe(
        val => {    
            this.seriesitems = JSON.parse(JSON.stringify(val)); 
            this.series =  this.seriesitems.getSeriesItems;
            
            if(this.seriesitems.getSeriesItems.thumbnail == "") {
                this.seriesthumbimage = 'assets/images/series-page-banner-2.png';
            } else {
                this.seriesthumbimage = this.seriesitems.getSeriesItems.thumbnail;
            }
          
            /* get season * /  
              const api_url = environment.apiMobUrl+"/getSeason?version=2&clientId=33&portalid=288&digest="+digest+"&channelId="+channelId+"&seriesId="+this.series_id;
              this.http.get(api_url)
              .subscribe(
                  val => {    
                      this.seasonresponse = JSON.parse(JSON.stringify(val));  
                      
                      if(this.seasonresponse.getSeason[0].thumbnail == "") {
                          this.seasonthumbimage = 'assets/images/series-page-banner-2.png';
                      } else {
                          this.seasonthumbimage = this.seasonresponse.getSeason.thumbnail;
                      } 
                      this.season = this.seasonresponse.getSeason;  

                  },
                  error => {
                      console.warn("This line is never called ",error);
                  } 
              );  
            /* get season end */

    /* get season items * /
    this.season_id = 917;
    const md5object = new Md5();
    const digest2 = (md5object.appendStr(environment.apiToken + this.season_id).end());
    const api_url2 = environment.apiMobUrl+"/getSeasonItems?version=2&clientId=33&portalid=288&digest="+digest2+"&seasonId="+this.season_id+"&channelId="+channelId+"&seriesId="+this.series_id;
    this.http.get(api_url2)
    .subscribe(
        val => {    
            this.season_item = JSON.parse(JSON.stringify(val));   
            console.warn( this.season_item.getSeasonItems.items);
        },
        error => {
            console.warn("This line is never called ",error);
        } 
    );  
  /* get season items end * / 

},
error => {
   console.warn("This line is never called ",error);
} 
);  
/* Get season list end */

  }

  onFavourite() {
    let param = {
      id: this.routeParams.id,
      type:'movies',
      user_id: this.userid,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    };
    const md5object3 = new Md5();
    const digest3 = (md5object3.appendStr( environment.apiToken+this.userid ).end());
    const api_url3 = environment.url + "/adduser_favourite?digest=" + digest3;
    this.http.post(api_url3, JSON.stringify(param))
      .subscribe(
        val => {
          this.favourite = JSON.parse(JSON.stringify(val));
          this.errMsg="Favourite added successfully.";
          
          let ele:HTMLElement=document.getElementById('errMsgLnk') as HTMLElement;
          ele.click();
          // if(this.favourite.result.success==1)
          // {
          //   // alert("favourite added");
          //   this.successmsg="Favourite Added."
          //   // alert(this.successmsg);
          //   this.favouritemsg=true;
          // }
          // else{
          //   // alert(this.favourite.errorMessage);
          //   this.alreadymsg=this.favourite.errorMessage
          //   this.favouritemsg=false;
          // }
         
        }
      )
  }

  slickInit(e) {
    console.log('slick initialized');
  }


}
