import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demovideo',
  templateUrl: './demovideo.component.html',
  styleUrls: ['./demovideo.component.css']
})
export class DemovideoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
