import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../_services';
import { Loginsession } from './../../_models'; 
import { Router, ActivatedRoute, Params } from '@angular/router';

import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5'; 
import { HttpClient } from '@angular/common/http';
import { Series,Season,Seriesitem,SeasonItem,Seriesitemseasonsitems,SeasonItems,Seasonlist } from './../../_models';
import { HelperService } from '../../_services/helper.service';   
@Component({
  selector: 'app-seriesitem',
  templateUrl: './seriesitem.component.html',
  styleUrls: ['./seriesitem.component.css']
})

export class SeriesitemComponent implements OnInit {
  private login_user_session:string;
  isLoggedIn = false;
  series_detail_page;
  private login_user:Loginsession;
  seriesitems:Seriesitem;
  season_item:SeasonItem;
  seasonitems:SeasonItems;
  seriesitemseasonsitems:Seriesitemseasonsitems;
  seasonlist:Seasonlist;
  slides_editor = [
    {img: "assets/images/editors_img1.jpg", caption:"Education"},
    {img: "assets/images/editors_img2.jpg", caption:"Business"},
    {img: "assets/images/editors_img3.jpg", caption:"Entertainment"},
    {img: "assets/images/editors_img4.jpg", caption:"Lifestyle"}, 
  ];

  slides_editor2 = [
    {img: "assets/images/editors_img1.jpg", caption:"Education"},
    {img: "assets/images/editors_img2.jpg", caption:"Business"},
    {img: "assets/images/editors_img3.jpg", caption:"Entertainment"},
    {img: "assets/images/editors_img4.jpg", caption:"Lifestyle"}, 
  ];
  
  routeParams: Params; 
  queryParams: Params;
  currentUrl;

  
  seriesresponse: Series; 
  seasonresponse: Season;
  loginresponse: Loginsession; 
  token;
  series; 
  season; 
  season_all;
  favourite;
  successmsg;
  alreadymsg;
  favouritemsg;
  errMsg;
  constructor( 
    protected route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private helperservice: HelperService) {
    
     if(localStorage.getItem('currentUser')){
        this.loginresponse = JSON.parse(localStorage.getItem('currentUser')); 
        const md5 = new Md5();
        this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end()); 
        this.login_user_session = this.loginresponse.result.session; 
        this.isLoggedIn = true;
        this.userid = this.loginresponse.result.userid;
     }
    // this.getRouteParams(); 
  }
   
   // Store parameter values on URL changes
    
   getRouteParams() { 
      // Route parameters
      this.activatedRoute.params.subscribe( (params: Params) => {
          this.routeParams = params;
      });

      // URL query parameters
      this.activatedRoute.queryParams.subscribe( (params: Params) => {
          this.queryParams = params;
      });
  }
  
  slideConfig = {
    "slidesToShow": 4, 
    "slidesToScroll": 3,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":false,
    // "autoplay":false, 
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  };
  slides = [];
  current_play = "video_1";
  seriesthumbimage:string;
  seasonthumbimage:string;
  season_id;
  series_id;
  item_id;
  userid;
  ngOnInit() {
    
    let video_url_1 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/maleficent1-HD.mp4/playlist.m3u8?extsessionid='+this.login_user_session;
    let video_url_2 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/abominable-HD.mp4/playlist.m3u8?extsessionid='+this.login_user_session;
    let video_url_3 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/joker-HD.mp4/playlist.m3u8?extsessionid='+this.login_user_session;
    let video_url_4 = "http://packageb.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageB/Programs/starwars_vod-25f-16x9-HD.mp4/playlist.m3u8?extsessionid="+this.login_user_session;
    let video_url_5 = 'http://packageb.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageB/Programs/maleficient2_vod-25f-16x9-HD.mp4/playlist.m3u8?extsessionid='+this.login_user_session;
 
    this.slides = [
      {id:"video_1", img: "assets/images/popular-pic1.jpg", video:video_url_1, caption:"Ballad of Buster Scruggs 1 ", title:"Drama"},
      {id:"video_2", img: "assets/images/popular-pic2.jpg", video:video_url_2, caption:"Ballad of Buster Scruggs 2", title:"Drama"},
      {id:"video_3", img: "assets/images/popular-pic3.jpg", video:video_url_3, caption:"Ballad of Buster Scruggs 3", title:"Drama"},
      {id:"video_4", img: "assets/images/popular-pic4.jpg", video:video_url_4, caption:"Ballad of Buster Scruggs 4", title:"Drama"},
      {id:"video_5", img: "assets/images/popular-pic1.jpg", video:video_url_5, caption:"Ballad of Buster Scruggs 5", title:"Drama"},
      {id:"video_6", img: "assets/images/popular-pic3.jpg", video:video_url_3, caption:"Ballad of Buster Scruggs 6", title:"Drama"},
      {id:"video_7", img: "assets/images/popular-pic4.jpg", video:video_url_4, caption:"Ballad of Buster Scruggs 7", title:"Drama"},
      {id:"video_8", img: "assets/images/popular-pic1.jpg", video:video_url_5, caption:"Ballad of Buster Scruggs 8", title:"Drama"},
    ];
    this.currentUrl=this.router.url;
    this.currentUrl=this.currentUrl.replace("#open-modal","");
    this.currentUrl=this.currentUrl.replace("#close","");
    console.log(this.currentUrl);
 

      /* Get series item */ 
      this.getRouteParams(); 
      if( this.routeParams.id <= 0) {
        this.router.navigate(['/login']);
      }
      let channelId = 845; 
      this.series_id = this.routeParams.id;
      const md5object = new Md5();
      const digest= (md5object.appendStr(environment.apiToken + this.series_id).end()); 
      const api_url = environment.url+"/getseries_detail?digest="+digest+"&sid="+this.series_id;
      const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
      this.http.get(api_url + extra_api_url)
      .subscribe(
          val => {    
            this.series_detail_page = JSON.parse(JSON.stringify(val)); 
            this.series_detail_page =  JSON.parse(JSON.stringify(this.series_detail_page.result)); 
            console.log(this.series_detail_page);
          /*  
             this.seriesitems = JSON.parse(JSON.stringify(val)); 
             this.series =  this.seriesitems.getSeriesItems;
             
              //this.seasonlist =  this.seriesitems.getSeriesItems.seasons;
              this.season = this.seriesitems.getSeriesItems.seasons[0];
              this.season_all = this.seriesitems.getSeriesItems.seasons;
              
              // series For loop start ========================================
              for (let i = 0; i < this.seriesitems.getSeriesItems.length; i++) {

                this.series =  this.seriesitems.getSeriesItems[i];
                if(this.seriesitems.getSeriesItems[i].thumbnail == "") {
                   this.seriesthumbimage = 'assets/images/series-page-banner-2.png';
                } else {
                   this.seriesthumbimage = this.seriesitems.getSeriesItems[i].thumbnail;
                }

                // season For loop start ================== 
                for (let i = 0; i < this.seriesitems.getSeriesItems[i].seasons.length; i++) {
                     this.season = this.seriesitems.getSeriesItems[i].seasons[0];
                }
                // season For loop end ====================



            }
            // series For loop end ==========================================
*/

          },
          error => {
              console.warn("This line is never called ",error);
          } 
      );  
     /* Get season list end */

  } 

  slideConfig_editor = {
    "slidesToShow": 5, 
    "slidesToScroll": 4,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":true
  };

  slickInit(e) {
    console.log('slick initialized');
  }

  onFavourite(){
    let param = {
      id: this.series_id ,
      type:'series',
      user_id: this.userid,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    };
    const md5object3 = new Md5();
    const digest3 = (md5object3.appendStr( environment.apiToken+this.userid ).end());
    const api_url3 = environment.url + "/adduser_favourite?digest=" + digest3;
    this.http.post(api_url3, JSON.stringify(param))
      .subscribe(
        val => {
          this.favourite = JSON.parse(JSON.stringify(val));

          this.errMsg="Favourite added successfully.";
          
          let ele:HTMLElement=document.getElementById('errMsgLnk') as HTMLElement;
          ele.click();

          // if(this.favourite.result.success==1)
          // {
          //   // alert("favourite added");
          //   this.successmsg="Favourite Added."
          //   // alert(this.successmsg);
          //   this.favouritemsg=true;
          // }
          // else{
          //   // alert(this.favourite.errorMessage);
          //   this.alreadymsg=this.favourite.errorMessage
          //   this.favouritemsg=false;
          // }
         
        }
      )
    
  }

}
