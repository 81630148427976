import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { AlertService, AuthenticationService } from '../../_services';
import { Loginsession } from '../../_models';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Packagedetail } from '../../_models';
import { HelperService } from '../../_services/helper.service';

@Component({
  selector: 'app-resubscribe',
  templateUrl: './resubscribe.component.html',
  styleUrls: ['./resubscribe.component.css']
})
export class ResubscribeComponent implements OnInit {
  loginresponse: Loginsession;
  // private login_user:Loginsession;
  private login_user_session: string;
  token;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private helperservice: HelperService
  ) {
    if (localStorage.getItem('userid') && localStorage.getItem('userid')!='') {
      this.userid = JSON.parse(localStorage.getItem('userid'));

      localStorage.setItem('userid','');

      let param = { "id": this.userid ,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
      };
      const md5 = new Md5();
      const digest = (md5.appendStr(environment.apiToken + this.userid).end());
      // const create_api_url = environment.url+"/get_users_current_plan_details?digest="+digest;
      const create_api_url = environment.url + "/get_users_current_plan_details?digest=" + digest + "&id=" + this.userid;
      this.http.post(create_api_url, JSON.stringify(param)).subscribe(
        val => {
          this.userdetails = JSON.parse(JSON.stringify(val));
          console.log(this.userdetails);
          if (parseInt(this.userdetails.errorCode) == 0) {
            this.firstName = this.userdetails.result.first_name;
            this.lastName = this.userdetails.result.last_name;
            this.email = this.userdetails.result.email
          } else {
            this.resmessage = environment.error.getSubscriptionPlanDetails[parseInt(this.userdetails.errorCode)];
          }
        }
      );

    }
    else{
      window.location.href='/login';
    }
  }
  email
  lastName;
  userdetail;
  firstName;
  isLoggedIn = false;
  packagedetail: Packagedetail;
  userdetails;
  planlist;
  signupurl = "/signup/1";
  planclass = "";
  errmessage = "";
  plan_price = "";
  plan_currency = "";
  plan_id = "";
  plan_name = "";
  resmessage;
  userid;
  userid1;
  onplanclick(plan, event, obplan) {
    this.signupurl = "/signup/" + plan;
    this.planclass = plan;
  }

  ngOnInit() {
    const md5 = new Md5();
    const digest = (md5.appendStr(environment.apiToken).end());

    const create_user_api_url = environment.url + "/getReSubscriptionPlans?digest=" + digest;
    const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
    this.http.get(create_user_api_url + extra_api_url).subscribe(
      val => {
        this.packagedetail = JSON.parse(JSON.stringify(val));
        console.log(this.packagedetail);
        if (parseInt(this.packagedetail.errorCode) == 0) {
          this.planlist = JSON.parse(JSON.stringify(val));
          this.planlist = this.planlist.result;
        } else {
          this.errmessage = environment.error.getSubscriptionPlans[parseInt(this.packagedetail.errorCode)];
        }
      },
      error => {
        console.warn("This line is never called ", error);
      },
      () => console.warn("registration process completed...")
    );
}

  payStackPayment() {
    const md5 = new Md5();
    const digest = (md5.appendStr(environment.apiToken + this.planclass).end());
    const create_api_url = environment.url + "/getSubscriptionPlanDetails?digest=" + digest + "&pid=" + this.planclass;
    let param = { "pid": this.planclass,
    device: this.helperservice.dev,device_unique: this.helperservice.browserversion,qid: this.helperservice.qid
  };
    this.http.post(create_api_url, JSON.stringify(param)).subscribe(
      val => {
        this.packagedetail = JSON.parse(JSON.stringify(val));
        if (parseInt(this.packagedetail.errorCode) == 0) {
          this.userid1 = this.userid;
          this.plan_price = this.packagedetail.result.backend_price;
          this.plan_currency = this.packagedetail.result.backend_currency;
          this.plan_id = this.packagedetail.result.plan_id;
          this.plan_name = this.packagedetail.result.plan_name;

        } else {
          this.resmessage = environment.error.getSubscriptionPlanDetails[parseInt(this.packagedetail.errorCode)];
        }
      }
    );
  }

}
