// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from '../app/_models/environment'; 
export const environment: Environment = {
  production: true, 
  apiUrl: 'https://api.visionip.tv/api/JSON',
  apiMobUrl: 'https://api.visionip.tv/api/JSONMOBILE',
  apiToken: '52b83c32cf03fe5a2888758d73a27ca6',
  url:' https://cms.ariseplay.com/index.php/api/JSONMOBILE',
  error:{
    login: {
      120:"Digest Error",
      123:"Usename Error",
      124:"Password Error",
      121:"username or password invalid",
      113:"Maximum number of sessions per user exceeded.",
      137:"User not activated",
      // 199:"You have already un-subscribed your account"
      199:"Your subscription has expired, please resubscribe to reactivate your account."
    },
    logout: {
      120:"Digest Error",
      205:"Extsessionid is missing", 
    },  
    changePassword: {
      120:"Digest Error",
      235:"new password is missing",
      123:"Usename Error",
      222:"Password is missing",
      203:"Username or Password Wrong",
      126:"New password is not valid"
    },
    getUserData: {
      120:"Digest Error",
      127:"User id error",
      236:"User id missing", 
    },
    editUserDetails: {
      120:"Digest Error",
      205:"Extsessionid is missing",
      121:"Invalid User Id", 
    },
    createUser: { 
      120:"Digest Error",
      223:"First name is missing",
      224:"Last name is missing",
      129:"Email is missing",
      125:"Email is not valid",
      221:"Username is missing",
      123:"Username Error",
      133:"Email Already Exist",
      124:"Password is not valid",
      222:"Password is missing",
    },
    forgottenPassword: {
      120:"Digest Error",
      221:"Username is missing",
      123:"Username Error",
    },
    getSubscriptionPlans: {
      120:"Digest Error",
      101:"No Active Plans", 
    },
    getSubscriptionPlanDetails: {
      120:"Digest Error",
      145:"Plan Id is missing", 
      146:"Plan is not Active", 
      147:"Plan is not available", 
    },
    getUserDeviceHistory: {
      120:"Digest Error",
      236:"User id missing", 
      127:"User id error", 
    },
    getUserOrderHistory: {
      120:"Digest Error",
      236:"User id missing", 
      127:"User id error", 
    },
  }
};



/*url
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
