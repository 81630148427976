import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Genre, Genrelist, Genrelistitem,Homecategoryitemroot,Homecategoryresult } from './../../_models'; 
import { Loginsession } from './../../_models'; 
import { HelperService } from '../../_services/helper.service';

@Component({
  selector: 'app-genrelist',
  templateUrl: './genrelist.component.html',
  styleUrls: ['./genrelist.component.css']
})
export class GenrelistComponent implements OnInit {

  loginresponse: Loginsession;
  login_user_session="";
  token;
  series; 
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private helperservice: HelperService) {
    
     if(localStorage.getItem('currentUser')){
        this.loginresponse = JSON.parse(localStorage.getItem('currentUser')); 
        const md5 = new Md5();
        this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end()); 
        this.login_user_session = this.loginresponse.result.session; 
     }
  }

  slides = [
    {img: "assets/images/slider-blank.png"},
    {img: "assets/images/slider-blank.png"} 
  ];


  slideConfig_editor = {
    "slidesToShow": 4, 
    "slidesToScroll": 4,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true, 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  };
  
  slideConfig = {
    "slidesToShow": 4, 
    "slidesToScroll": 3,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true, 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  };

  
  slideConfig3 = {
    "slidesToShow": 4, 
    "slidesToScroll": 3,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":true, 
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  };

  slideConfig_genre_list = {
    "slidesToShow": 4, 
    "slidesToScroll": 3,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  };

  addSlide() {
    this.slides.push({img: "assets/images/banner-pic.jpg"})
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }
    

  Genre: Genre; 
  Genrelist:Genrelist;
  Genrelistitem:Genrelistitem; 
  genre_list=[];
  final_genre_list = [];

  newitemarray = []; 

  ob_homecategory:Homecategoryresult;
  ob_homecategory_items:Homecategoryitemroot;
  ngOnInit() { 

    //----------- Category list ----------
     // get home page category  
     let paramhomecategory = { 
      "pid": 1,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
  };
  const md5object1 = new Md5();
  const password_digest= (md5object1.appendStr(environment.apiToken).end()); 
  const home_categories = environment.url+"/getgenre_category?digest="+password_digest;
  this.http.post(home_categories,JSON.stringify(paramhomecategory))
  .subscribe(
      val => {   
        console.log(val);
        this.ob_homecategory = JSON.parse(JSON.stringify(val));
        for (let i = 0; i < this.ob_homecategory.result.length; i++) {
          console.warn( this.ob_homecategory.result[i].name, this.ob_homecategory.result[i].genreid);
          //----------------------
          // get home page category items 
            let paramhomecategoryitems = { 
              "gid": this.ob_homecategory.result[i].genreid,
              device: this.helperservice.dev,
              device_unique: this.helperservice.browserversion,
              qid: this.helperservice.qid
            };
            const md5object1 = new Md5();
            const password_digest1= (md5object1.appendStr(environment.apiToken+this.ob_homecategory.result[i].genreid).end()); 

            const home_categoriesitems = environment.url+"/getmovies_genrevideo?gid="+this.ob_homecategory.result[i].genreid+"&digest="+password_digest1;
            this.http.post(home_categoriesitems,JSON.stringify(paramhomecategoryitems))
            .subscribe(
                val => {   
                  //console.log(val);
                  this.ob_homecategory_items = JSON.parse(JSON.stringify(val));
                  this.ob_homecategory.result[i].homecategoryitem = this.ob_homecategory_items.result;
                //  console.log(this.ob_homecategory_items.result);
                  //   this.ob_homevideo = JSON.parse(JSON.stringify(val));  
                }
            );
          // get home page category items end
          //----------------------
        }
     //   this.ob_homevideo = JSON.parse(JSON.stringify(val));  
      }
  );
  // get home page category end
    //------------------------------------

    const md5object = new Md5();
    const digest= (md5object.appendStr(environment.apiToken + "845").end()); 
    const api_url = environment.apiMobUrl+"/getGenre?version=2&clientId=33&portalid=288&channelId=845&digest="+digest;
    const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;  
    this.http.get(api_url + extra_api_url)
    .subscribe(
        val => {   
            this.Genre = JSON.parse(JSON.stringify(val));  
            for( let ab= 0; this.Genre.getGenre.length > ab; ab++) {
              // this.genre_list.push([{ name: this.Genre.getGenre[ab].genre, allitems:[] }]);
              this.genre_list.push(this.Genre.getGenre[ab].genre); 
            } 
            /* Genre item list */
            const md5object = new Md5();
            const digest= (md5object.appendStr(environment.apiToken + "845").end()); 
            const api_url = environment.apiMobUrl+"/getItemList?version=2&clientId=33&portalid=288&channelid=845&digest="+digest;  
            const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;  
            this.http.get(api_url + extra_api_url)
            .subscribe(
                val => {   
                    this.Genrelist = JSON.parse(JSON.stringify(val));  
                   

                    for( let ab1 = 0; this.genre_list.length > ab1; ab1++ ) { 
                    //  console.log(this.genre_list[ab1]);
                       this.newitemarray[this.genre_list[ab1]] = [];
                       for( let a= 0; this.Genrelist.getItemList.length > a; a++ ) { 
                          if(this.Genrelist.getItemList[a].genre ==  this.genre_list[ab1]){
                            this.newitemarray[this.Genrelist.getItemList[a].genre].push( this.Genrelist.getItemList[a]); 
                          }
                       } 
                    }
                    
                   
                    console.log("---"); 
                    console.warn(this.newitemarray); 
                    
                },
                error => {
                    console.warn("This line is never called ",error);
                },
                () => console.warn("registration process completed...")
            ); 
            /* Genre item list end */

        },
        error => {
            console.warn("This line is never called ",error);
        },
        () => console.warn("registration process completed...")
    ); 

  }
}
