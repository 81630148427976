import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
//import { jqueryModule } from 'jquery';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AppRoutingModule } from './app-routing.module';
import { CmspageModule } from './cmspage/cmspage.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BannerComponent } from './banner/banner.component';
import { FooterComponent } from './footer/footer.component';
import { MiddleComponent } from './middle/middle.component';
import { RegisterComponent } from './guest/register/register.component';
import { LoginComponent } from './guest/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EmbedVideo } from 'ngx-embed-video';
import { MoviesComponent } from './cmspage/movies/movies.component'; 
import { AccountComponent } from './account/account.component';
import { WelcomeComponent } from './account/welcome/welcome.component';
import { SignupComponent } from './guest/signup/signup.component';
import { ResetpasswordComponent } from './account/resetpassword/resetpassword.component';
import { ForgotpasswordComponent } from './guest/forgotpassword/forgotpassword.component'; 
import { AllseriesComponent } from './cmspage/allseries/allseries.component'; 
import { SeriesitemComponent } from './cmspage/seriesitem/seriesitem.component'; 
import { GenredetailComponent } from './cmspage/genredetail/genredetail.component'; 
import { PlaymovieComponent } from './cmspage/playmovie/playmovie.component'; 
import { GenrelistComponent } from './cmspage/genrelist/genrelist.component'; 
import { GenreComponent } from './cmspage/genre/genre.component'; 
import { PrpageComponent } from './cmspage/prpage/prpage.component'; 
import { PlanComponent } from './cmspage/plan/plan.component';  
import { PaywelcomeComponent } from './cmspage/welcome/welcome.component';
import { HistoryComponent } from './account/history/history.component';
import { DevicesComponent } from './account/devices/devices.component'; 

import { AudioComponent } from './cmspage/audio/audio.component'; 
import { ArticlesComponent } from './cmspage/articles/articles.component'; 
import { MainmoviesComponent } from './cmspage/mainmovies/mainmovies.component';
import { MainseriesComponent } from './cmspage/mainseries/mainseries.component'; 
import { TermsconditionsComponent } from './cmspage/termsconditions/termsconditions.component'; 
import { PrivacyComponent } from './cmspage/privacy/privacy.component'; 
import { PaymentComponent } from './cmspage/payment/payment.component'; 
import { EpisodeComponent } from './cmspage/episode/episode.component';
import { LiveComponent } from './live/live.component';
import { SearchComponent } from './search/search.component';
import { SupportComponent } from './support/support.component';
import { EventComponent } from './event/event.component';
import { FavouriteComponent } from './account/favourite/favourite.component';
import { UsersmanagementComponent } from './account/usersmanagement/usersmanagement.component';
import { AddedituserComponent } from './account/addedituser/addedituser.component';
import { EdituserComponent } from './account/edituser/edituser.component';
import { from } from 'rxjs';
import {NgxPaginationModule} from 'ngx-pagination';
import { SubscriptiondetailsComponent } from './account/subscriptiondetails/subscriptiondetails.component';
import { ListpaymentComponent } from './account/listpayment/listpayment.component';
import { AllkidsseriesComponent } from './cmspage/allkidsseries/allkidsseries.component';
import { SportsComponent } from './sports/sports.component';
import { SportslistComponent } from './sportslist/sportslist.component';
import { SportsdetailsComponent } from './sportsdetails/sportsdetails.component';
import { CompetitionruleComponent } from './competitionrule/competitionrule.component';
import { DemovideoComponent } from './demovideo/demovideo.component';
import { DeviceDetectorModule,DeviceDetectorService } from 'ngx-device-detector';
import { PinPasswordComponent } from './account/pinpassword/pinpassword.component';

@NgModule({
  declarations: [
    DemovideoComponent,
    AppComponent,
    HeaderComponent,
    BannerComponent,
    FooterComponent,
    MiddleComponent,
    RegisterComponent,
    MoviesComponent,
    LoginComponent,
    AccountComponent,
    WelcomeComponent,
    SignupComponent,
    ResetpasswordComponent,
    ForgotpasswordComponent,
    AllseriesComponent,
    SeriesitemComponent,
    GenredetailComponent,
    PlaymovieComponent,
    GenrelistComponent,
    PrpageComponent,
    PlanComponent,
    PaywelcomeComponent,
    HistoryComponent,
    DevicesComponent,
    AudioComponent,
    ArticlesComponent,
    MainmoviesComponent,
    MainseriesComponent,
    GenreComponent,
    TermsconditionsComponent,
    PrivacyComponent,
    PaymentComponent,
    EpisodeComponent,
    LiveComponent,
    SearchComponent,
    SupportComponent,
    EventComponent,
    FavouriteComponent,
    UsersmanagementComponent,
    AddedituserComponent,
    EdituserComponent,
    SubscriptiondetailsComponent,
    ListpaymentComponent,
    AllkidsseriesComponent,
    SportsComponent,
    SportslistComponent,
    SportsdetailsComponent,
    CompetitionruleComponent,
    PinPasswordComponent,
  ], 
  imports: [
    BrowserModule,  
    CmspageModule,
    AppRoutingModule,
    // NgbModule
    SlickCarouselModule,
    // jqueryModule
    AngularFontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    EmbedVideo.forRoot(),
    NgxPaginationModule,
    DeviceDetectorModule
  ],
  providers: [DeviceDetectorService],
  bootstrap: [AppComponent]
})
export class AppModule { }
