import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Loginsession } from './../../_models';
import { HelperService } from '../../_services/helper.service';

@Component({
  selector: 'app-listpayment',
  templateUrl: './listpayment.component.html',
  styleUrls: ['./listpayment.component.css']
})
export class ListpaymentComponent implements OnInit {

  loginresponse: Loginsession;
  token;
  login_user_session;
  userid;
  is_corporate_acc;
  resp;
  currentUrl;
  defaultid;
  deleteid;
  errmsg;
  plan_price;
  plan_currency="";
  plan_id="";
  plan_name="";
  firstName="";
  lastName="";
  email="";
  userid1="";
  packagedetail;
  resmessage;
  userdetails;
  role_type;
  

  constructor(private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private helperservice: HelperService  ) {
      this.loginresponse = JSON.parse(localStorage.getItem('currentUser'));
      const md5 = new Md5();
      this.token = (md5.appendStr(environment.apiToken + this.loginresponse.result.session).end());
      this.login_user_session = this.loginresponse.result.session;
  
      this.userid = this.loginresponse.result.userid;
      this.is_corporate_acc = this.loginresponse.result.is_corporate_acc;
      this.role_type = this.loginresponse.result.role_type;
      this.errmsg = localStorage.getItem('err_msg');
      localStorage.setItem('err_msg','');


      let param = { "id": this.userid,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid };
      const md51 = new Md5();
      const digest = (md51.appendStr(environment.apiToken + this.userid).end());
      // const create_api_url = environment.url+"/get_users_current_plan_details?digest="+digest;
      const create_api_url = environment.url + "/get_users_current_plan_details?digest=" + digest + "&id=" + this.userid;
      this.http.post(create_api_url, JSON.stringify(param)).subscribe(
        val => {
          this.userdetails = JSON.parse(JSON.stringify(val));
          console.log(this.userdetails);
          if (parseInt(this.userdetails.errorCode) == 0) {
            this.firstName = this.userdetails.result.first_name;
            this.lastName = this.userdetails.result.last_name;
            this.email = this.userdetails.result.email;
            this.plan_id = this.userdetails.result.plan_id;
          } else {
            this.resmessage = environment.error.getSubscriptionPlanDetails[parseInt(this.userdetails.errorCode)];
          }
        }
      );
      
     }

  ngOnInit() {

    this.currentUrl = this.router.url;
    this.currentUrl = this.currentUrl.replace("#open-modal", "");
    this.currentUrl = this.currentUrl.replace("#close", "");
    this.currentUrl = this.currentUrl.replace("#open-modal1", "");
    let param = {
      user_id:  this.userid,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    }
    const md5object = new Md5();
    const digest= (md5object.appendStr(environment.apiToken+this.userid).end());
    const create_user_api_url = environment.url+"/get_user_payment_methods?digest="+digest;
    this.http.post(create_user_api_url, JSON.stringify(param))
        .subscribe(
          val => {
            this.resp = JSON.parse(JSON.stringify(val));
          }
        )
  }

  defaultpayment()
  {
    let param = {
      id:this.defaultid,
      device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
    }
    const md5object = new Md5();
    const digest= (md5object.appendStr(environment.apiToken+this.defaultid).end());
    const create_user_api_url = environment.url+"/update_the_default_payment_method?digest="+digest;
    this.http.post(create_user_api_url, JSON.stringify(param))
        .subscribe(
          val => {
            this.resp = JSON.parse(JSON.stringify(val));
            // console.log(this.resp);
            // alert(JSON.stringify(this.resp));
            window.location=this.currentUrl;
          }
        )
  }

  deletepaymentmethod(){
    {
      let param = {
        id:this.deleteid,
        device: this.helperservice.dev,
        device_unique: this.helperservice.browserversion,
        qid: this.helperservice.qid
      }
      const md5object = new Md5();
      const digest= (md5object.appendStr(environment.apiToken+this.deleteid).end());
      const create_user_api_url = environment.url+"/delete_the_default_payment_method?digest="+digest;
      this.http.post(create_user_api_url, JSON.stringify(param))
          .subscribe(
            val => {
              this.resp = JSON.parse(JSON.stringify(val));
              // console.log(this.resp);
              localStorage.setItem('err_msg',this.resp.errorMessage);
              location.href='/account/listpayment';
            }
          )
    }
  }

  onDefault(id)
  {
    this.defaultid=id;
    let ele:HTMLElement=document.getElementById('popuplnk') as HTMLElement;
    ele.click();
  }

  onDelete1(id){
    this.deleteid=id;
    let ele:HTMLElement=document.getElementById('deletelnk') as HTMLElement;
    ele.click();
  }

  // payStackPayment() {

  //   // this.userid1 =this.loginresponse.result.userid;
  //   // this.plan_price='1143';
  //   this.plan_currency='NGN';
  //   // this.plan_id='1';
  //   // this.plan_name='MONTHLY - 1 MONTH FREE';
  //   // this.firstName='Mohitt';  
  //   // this.lastName='Guptaa';
  //   // this.email='mg.k88@gmail.com';
  //   let param = {"id":this.userid };
  //   const md5 = new Md5(); 
  //   const digest = (md5.appendStr(environment.apiToken+this.userid).end()); 
  //   // const create_api_url = environment.url+"/get_users_current_plan_details?digest="+digest;
  //   const create_api_url = environment.url+"/get_users_current_plan_details?digest="+digest+"&id="+this.userid;    
  //      this.http.post(create_api_url,JSON.stringify(param)).subscribe(
  //          val => {    
  //              this.packagedetail = JSON.parse(JSON.stringify(val));
  //              console.log(this.packagedetail);
  //              if(parseInt(this.packagedetail.errorCode) == 0 ){ 
  //                   this.userid1 =this.loginresponse.result.userid;
  //                    this.plan_price=this.packagedetail.result.plan_price;
  //                    console.log(this.plan_price)
  //                   //  this.plan_currency=this.packagedetail.result.plan_currency;
  //                   //  console.log(this.plan_currency);
  //                    this.plan_id=this.packagedetail.result.plan_id;
  //                    this.plan_name=this.packagedetail.result.plan_name;
  //                    this.firstName=this.packagedetail.result.first_name;
  //                    this.lastName=this.packagedetail.result.last_name;
  //                    this.email = this.packagedetail.result.email
  //                   } else {
  //                this.resmessage = environment.error.getSubscriptionPlanDetails[parseInt(this.packagedetail.errorCode)]; 
  //              }
  //          }
  //       );
  // }

  payStackPayment() {
    const md5 = new Md5();
    const digest = (md5.appendStr(environment.apiToken + this.plan_id).end());
    const create_api_url = environment.url + "/getSubscriptionPlanDetails?digest=" + digest + "&pid=" + this.plan_id;
    let param = { "pid": this.plan_id,
    device: this.helperservice.dev,
      device_unique: this.helperservice.browserversion,
      qid: this.helperservice.qid
  };
    this.http.post(create_api_url, JSON.stringify(param)).subscribe(
      val => {
        this.packagedetail = JSON.parse(JSON.stringify(val));
        if (parseInt(this.packagedetail.errorCode) == 0) {
          this.userid1 = this.userid;
          this.plan_price = this.packagedetail.result.backend_price;
          this.plan_currency = this.packagedetail.result.backend_currency;
          this.plan_id = this.packagedetail.result.plan_id;
          this.plan_name = this.packagedetail.result.plan_name;

        } else {
          this.resmessage = environment.error.getSubscriptionPlanDetails[parseInt(this.packagedetail.errorCode)];
        }
      }
    );
  }

}
