import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../_services';
import { Loginsession } from './../../_models'; 
import { Router, ActivatedRoute, Params } from '@angular/router';

import { environment } from './../../../environments/environment'; 
import { Md5 } from 'ts-md5/dist/md5'; 
import { HttpClient } from '@angular/common/http';
import { Series,Season,Seriesitem,SeasonItem,Geturl,SeasonItems,Seriesitemseasonsitems } from './../../_models'; 
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { HelperService } from '../../_services/helper.service';
@Component({
  selector: 'app-playmovie',
  templateUrl: './playmovie.component.html',
  styleUrls: ['./playmovie.component.css']
})
export class PlaymovieComponent implements OnInit { 

  private login_user_session:string;
  isLoggedIn = false;
  private login_user:Loginsession;
  seriesitems:Seriesitem;
  season_item:SeasonItem;
  all_season_items:Seriesitemseasonsitems;
  seriesresponse: Series; 
  seasonresponse: Season;
  geturl:Geturl;
  seasontabtitle:string;
  slides_editor = [
    {img: "assets/images/editors_img1.jpg", caption:"Education"},
    {img: "assets/images/editors_img2.jpg", caption:"Business"},
    {img: "assets/images/editors_img3.jpg", caption:"Entertainment"},
    {img: "assets/images/editors_img4.jpg", caption:"Lifestyle"}, 
  ];

  slides_editor2 = [
    {img: "assets/images/editors_img1.jpg", caption:"Education"},
    {img: "assets/images/editors_img2.jpg", caption:"Business"},
    {img: "assets/images/editors_img3.jpg", caption:"Entertainment"},
    {img: "assets/images/editors_img4.jpg", caption:"Lifestyle"}, 
  ];
  
  routeParams: Params; 
  queryParams: Params;
  imageparam;
  token;
  constructor(private authenticationService: AuthenticationService, private router: Router,
    private http: HttpClient, private activatedRoute: ActivatedRoute,private helperservice: HelperService) { 
    if (this.authenticationService.currentUserValue) {
      this.login_user = JSON.parse(localStorage.getItem('currentUser')); 
      this.isLoggedIn = true;
      this.login_user_session = this.login_user.result.session;
      const md5 = new Md5();
      this.token = (md5.appendStr(environment.apiToken + this.login_user.result.session).end()); 
    }
    this.getRouteParams(); 
  }

   // Store parameter values on URL changes
   getRouteParams() {

      // Route parameters
      this.activatedRoute.params.subscribe( params => {
          this.routeParams = params;
      });

      // URL query parameters
      this.activatedRoute.queryParams.subscribe( params => {
          this.queryParams = params;
      });
  }
  
  slideConfig = {
    "slidesToShow": 4, 
    "slidesToScroll": 3,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  };
  slides = [];
  current_play = "video_1";
  season;
  season_all;
  ep_seasonitem:SeasonItems;
  thum_image;
  item_id;
  errMessage="";
  new_video = "http://arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/zkra_season01_0001_avi_vod-25f-16x9-FHD.mp4/playlist.m3u8?extsessionid=5decdb13e50a1-45dda3a0916126d581dea214e79bb067";
  ngOnInit() {

    let video_url_1 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/maleficent1-HD.mp4/playlist.m3u8?extsessionid='+this.login_user_session;
    let video_url_2 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/abominable-HD.mp4/playlist.m3u8?extsessionid='+this.login_user_session;
    let video_url_3 = 'http://packagea.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageA/Programs/joker-HD.mp4/playlist.m3u8?extsessionid='+this.login_user_session;
    let video_url_4 = "http://packageb.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageB/Programs/starwars_vod-25f-16x9-HD.mp4/playlist.m3u8?extsessionid="+this.login_user_session;
    let video_url_5 = 'http://packageb.arisestreamtest.visionip.tv/arisestreamvod/_definst_/arisestreamvod/PackageB/Programs/maleficient2_vod-25f-16x9-HD.mp4/playlist.m3u8?extsessionid='+this.login_user_session;
 
    this.slides = [
      {id:"video_1", img: "assets/images/popular-pic1.jpg", video:video_url_1, caption:"Ballad of Buster Scruggs 1 ", title:"Drama"},
      {id:"video_2", img: "assets/images/popular-pic2.jpg", video:video_url_2, caption:"Ballad of Buster Scruggs 2", title:"Drama"},
      {id:"video_3", img: "assets/images/popular-pic3.jpg", video:video_url_3, caption:"Ballad of Buster Scruggs 3", title:"Drama"},
      {id:"video_4", img: "assets/images/popular-pic4.jpg", video:video_url_4, caption:"Ballad of Buster Scruggs 4", title:"Drama"},
      {id:"video_5", img: "assets/images/popular-pic1.jpg", video:video_url_5, caption:"Ballad of Buster Scruggs 5", title:"Drama"},
      {id:"video_6", img: "assets/images/popular-pic3.jpg", video:video_url_3, caption:"Ballad of Buster Scruggs 6", title:"Drama"},
      {id:"video_7", img: "assets/images/popular-pic4.jpg", video:video_url_4, caption:"Ballad of Buster Scruggs 7", title:"Drama"},
      {id:"video_8", img: "assets/images/popular-pic1.jpg", video:video_url_5, caption:"Ballad of Buster Scruggs 8", title:"Drama"},
    ];
    this.getRouteParams(); 
    let video_key = "video_"+this.queryParams.view_video; 
    this.current_play = video_url_1;
    if(this.queryParams.view_video){
      if(this.queryParams.view_video == 1) {
        this.current_play = video_url_1;
      }
      if(this.queryParams.view_video == 2) {
        this.current_play = video_url_2;
      }
      if(this.queryParams.view_video == 3) {
        this.current_play = video_url_3;
      }
      if(this.queryParams.view_video == 4) {
        this.current_play = video_url_4;
      }
      if(this.queryParams.view_video == 5) {
        this.current_play = video_url_5;
      }
      if(this.queryParams.view_video == 6) {
        this.current_play = video_url_4;
      }
      if(this.queryParams.view_video == 7) {
        this.current_play = video_url_5;
      }
      if(this.queryParams.view_video == 8) {
        this.current_play = video_url_3;
      }
    }
   /* if(this.queryParams.view_video){
         this.slides.forEach(function (value) {
          if(video_key == value.id) {
            //this.current_play = value.video;
            if( value.video )
              this.current_play =  value.video;
          }
        }); 
    } */
    
     

      //////////////////////////////////
      /* Get series item */ 
      this.getRouteParams(); 
      if( this.routeParams.id <= 0) {
        this.router.navigate(['/login']);
      } 
      this.item_id = this.routeParams.id; 
      this.imageparam = decodeURI(this.queryParams.im);  
      /* get video url */ 
      const md5object3 = new Md5(); 
      const digest3 = (md5object3.appendStr(environment.apiToken + this.login_user_session).end());
      const api_url3 = environment.apiMobUrl+"/getUrl?version=2&clientId=33&portalid=288&digest="+digest3+"&itemid="+this.item_id+"&bitrate=MB&protocolid=5&extsessionid="+this.login_user_session;
      const extra_api_url = "&qid=" + this.helperservice.qid + "&dev=" + this.helperservice.dev + "&duni=" + this.helperservice.browserversion ;
      this.http.get(api_url3 + extra_api_url)
      .subscribe(
          val => {    
             this.geturl  = JSON.parse(JSON.stringify(val));   
            console.warn(this.geturl);
          //   console.warn(this.geturl.getUrl);
             if( this.geturl.getUrl[0].errorcode <= 0) {
              this.current_play = this.geturl.getUrl[0].url.split("?");
              this.current_play =  this.current_play[0]+"?extsessionid="+this.login_user_session;
              console.warn(this.current_play);
             } else {
                this.errMessage = "Error Code = "+ this.geturl.getUrl[0].errorcode;
             }
          },
          error => {
             console.warn("This line is never called ",error);
          } 
      );   
      /* end video url */
      
  }

  slideConfig_editor = {
    "slidesToShow": 5, 
    "slidesToScroll": 4,
    "nextArrow":"<div class='nav-btn next-slide'><img src='assets/images/vidslid_previmg.png'/></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='assets/images/vidslid_nextimg.png'/></div>",
    "dots":false,
    "arrows":true,
    "infinite":true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  };

  slickInit(e) {
    console.log('slick initialized');
  }
   

}
